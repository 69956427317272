
import { useCallback, useEffect, useState } from "react";
import { useInitDataStore } from "../../../../store/initData.store";

import useFormattedDate from "../../../../common/hooks/useFormattedDate";
import { shallow } from "zustand/shallow";
import DateInput from "../../../../common/UI/Inputs/InputDate";
import ConatinerFilter from "../../../../common/UI/DataTable/filterModal/containerFilter";
import DataTable from "../../../../common/UI/DataTable/dataTable";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";

import style from "./AttendaceTeachers.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";


import { DataSelectDto } from "../../../../common/dtos/general.dto";
import usePermission from "../../../../common/hooks/usePermission";
import { GetEmailsLogsWithPaginationService } from "../../services/EmailsLogs/getEmailsLogsWithPagination.service";
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import { GetLogsEntitiesWithPaginationService } from "../../services/getLogsEntitiesWithPagination.service";



import { GetAttendanceTeacherWithPaginationService } from "../../services/AttendanceTeacher/getAttendanceTeacherWithPagination.service";
import { GetHoursBlockWithPaginationService } from "../../../booking/services/getHoursBlockWithPagination.service";
import InputSearchFilter from "../../../../common/UI/Inputs/InputSearchFilter";
import AppInputSearchFilter from "../../../../common/UI/Inputs/appInputSearchFilter";
import { GetTermCodeWithPaginationService } from "../../../rotationScreen/services/DashboardScreenView/getTermCodeWithPagination.service";
import { GetBuildingNameWithPaginationService } from "../../../booking/services/getBuildingNameWithPagination.service";






const getAttendanceTeacherWithPaginationService = new GetAttendanceTeacherWithPaginationService();

export default function CampusReservationLimitPage() {
  const hFormatDate = useFormattedDate();
  const canListEmailLogs = usePermission('historical.attendace.teacher', 'list');

  const dataInitDataStore = useInitDataStore(
    (state) => ({
      userRoles: state.initData.userRoles.map(item => ({ value: String(item.roleId), label: item.role.name })),
      rolSelected: state.rolSelected,
      setItemRolePermissionId: state.setItemRolePermissionId,
      setRolSelected: state.setRolSelected,
      userCampus: state.initData?.userCampus ?? [].map((item: any) => ({
        campusId: String(item.campusId),
        campusName: String(item.campusName)
      })),
    }),
    shallow
  );
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [isReloadData, setIsReloadData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [startDate, setStartDate] = useState<any>(startOfMonth);
  const [endDate, setEndate] = useState<any>(endOfMonth);
  const [hoursBlockStart, setHoursBlockStart] = useState({ label: '', value: '' });
  const [hoursBlockEnd, setHoursBlockEnd] = useState({ label: '', value: '' });
  const [searchNrc, setSearchNrc] = useState<string>('');
  const [termCodes, setTermCodes] = useState<any>('');
  const [course, setCourse] = useState<string>('');
  const [teacher, setTeacher] = useState<string>('');
  const [room, setRoom] = useState<string>('');
  const [buildingIds, setBuildingIds] = useState<any>('');
  const [buildingIdFilter, setBuildingIdFilter] = useState<DataSelectDto>({
    label: "",
    value: "",
  });

  const [warningMessage, setWarningMessage] = useState("");

  const serviceCallHoursBlock = async (dataSearch: any) => {
    const response: any = await GetHoursBlockWithPaginationService(dataSearch);
    const filteredData = response.data
      .filter((item: any) => item.type === 0)
      .map((item: any) => ({
        ...item,
        label: item.hourBlock,
        value: item.id
      }));
    return filteredData;
  };

  const serviceCallHoursBlockEnd = async (dataSearch: any) => {
    const response: any = await GetHoursBlockWithPaginationService(dataSearch);
    const hourStart = hoursBlockStart;
    const filteredData = response.data.filter((item: any) => {
      const itemHour = item.hourBlock;
      const isTypeOne = item.type === 1;
      const isHourValid = itemHour > hourStart?.label;
      return isTypeOne && isHourValid;
    }).map((item: any) => {
      return {
        ...item,
        label: item.hourBlock,
        value: item.id,
      };
    });
    return filteredData;
  };
  const serviceCallTermCode = useCallback(async (value: string) => {
    const response: any = await GetTermCodeWithPaginationService({
      search: value,
      perPage: 1000
    });
    return response.data.map((item: any) => ({ label: item.SYBTERM_TERM_DESC, value: item.sybterm_term_code }));
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  const serviceCallBuildingFilter = useCallback(
      
      async (dataSearch: any) => {
        const response: any = await GetBuildingNameWithPaginationService({
          search: dataSearch,
          perPage: 1000,
        });
        console.log("hola bioild",response.data);
        
        return response.data.map((item: any) => ({
          label: item.buildingName,
          value: item.buildingName,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      []
    );
  const columns = [
    {
      header: "PERIODO ACADÉMICO",
      field: "periodName",
    },
    {
      header: "ID PERÍODO",
      field: "periodId",
    },
    {
      header: "BLOQUE INICIO",
      field: "startHour",
    },
    {
      header: "BLOQUE FIN",
      field: "endHour",
    },
    {
      header: "ID OCURRENCIA",
      field: "ocurrenceId",
    },
    {
      header: "CURSO",
      field: "courseName",
    },
    {
      header: "DESCRIPCIÓN CURSO",
      field: "courseDescription",
    },
    {
      header: "ID CURSO",
      field: "courseId",
    },
    {
      header: "SALA DE CLASES",
      field: "roomName",
    },
    {
      header: "EDIFICIO",
      field: "buildingName",
    },


    {
      header: "PROFESOR",
      field: "teacherName",
    },
    {
      header: "ID PROFESOR",
      field: "teacherId",
    },
    {
      header: "RUT PROFESOR",
      field: "teacherRut",
    },
    {
      header: "USUARIO MODIFICADOR",
      field: "modifierUser",
    },
    {
      header: "HORA DE INICIO",
      field: "classStartTime",
    },
    {
      header: "HORA FIN",
      field: "classEndTime",
    },
    {
      header: "ESTADO",
      field: "state",
    },
    {
      header: "COMENTARIO",
      field: "comment",
    },
    {
      header: "FECHA",
      field: "formatedDate",
    },
    {
      header: "NRC",
      field: "nrc",
    },

  ];


  const handleFilterButtonClick = () => {
    setShowFilterModal(true);
  };
  const handleFilterModalClose = () => {
    setShowFilterModal(false);
  };
  const [filterKey, setFilterKey] = useState(0); // Estado para forzar la actualización de DateInput
  const handleDeleteFilter = () => {
    setHoursBlockStart({ label: "", value: "" });
    setHoursBlockEnd({ label: "", value: "" });
    setSearchNrc('');
    setTermCodes('');
    setCourse('');
    setTeacher('');
    setRoom('');
    setBuildingIds('');
    setBuildingIdFilter({ label: "", value: "" });
    setFilterKey((prevKey) => prevKey + 1); // Forzar la actualización de DateInput
  };
  // Lógica de validación
  const validateFilter = () => {
    if (!startDate || !endDate) {
      setWarningMessage("El rango de fechas es obligatorio.");
      return false;
    }
    setWarningMessage("");
    return true;
  };
  // Manejador del filtro
  const handleFilter = () => {
    if (validateFilter()) {
      setIsReloadData(!isReloadData);
    }
  };

  const updateGlobalState = (itemRolePermissionId: number) => {
    dataInitDataStore.setItemRolePermissionId(itemRolePermissionId);
  };

  useEffect(() => {
    updateGlobalState(canListEmailLogs[1])
    const data = Array.isArray(buildingIdFilter) ? buildingIdFilter : [];
    let databuildingIds = data.length ? data.map((item) => item.value).join(",") : "";

    setBuildingIds({ label: databuildingIds, value: databuildingIds });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingIdFilter]);
  return (
    <div className={style.containerQr}>
      <DataTable
        title="Historico asistencia docente"
        columns={columns}
        service={getAttendanceTeacherWithPaginationService}
        params={{
          startDate: startDate ? hFormatDate(startDate, "yyyy-MM-dd") : "",
          endDate: endDate ? hFormatDate(endDate, "yyyy-MM-dd") : "",
          startHour: hoursBlockStart.label ?? null,
          endHour: hoursBlockEnd.label ?? null,
          nrc: searchNrc ?? null,
          periodId: termCodes ?? null,
          course: course ?? null,
          teacher: teacher ?? null,
          room: room ?? null,
          building: buildingIds.value ?? '',  // Asigna el valor de `buildingIds` o "" si es `null`
        }}
        showSearch={false} // Esto ocultará el buscador
        reloadData={isReloadData}
        excelButton={{
          enabled: true,
          nameFile: `Historico asistencia docente`,
          getExcelData: (data) => {
            return data.map((row) => ({
              "PERIODO ACADÉMICO": row.periodName,
              "ID PERÍODO": row.periodId,
              "BLOQUE INICIO": row.startHour,
              "BLOQUE FIN": row.endHour,
              "ID OCURRENCIA": row.ocurrenceId,
              "CURSO": row.courseName,
              "DESCRIPCIÓN CURSO": row.courseDescription,
              "ID CURSO": row.courseId,
              "SALA DE CLASES": row.roomName,
              "EDIFICIO": row.buildingName,
              "PROFESOR": row.teacherName,
              "ID PROFESOR": row.teacherId,
              "RUT PROFESOR": row.teacherRut,
              "USUARIO MODIFICADOR": row.modifierUser,
              "HORA DE INICIO": row.classStartTime,
              "HORA FIN": row.classEndTime,
              "ESTADO": row.state,
              "COMENTARIO": row.comment,
              "FECHA": row.formatedDate,
              "NRC": row.nrc,
            }));
          },
        }}
      >
        <ConatinerFilter
          nameIcon="filter"
          onClick={() => handleFilterButtonClick()}
          isOpen={showFilterModal}
          onClose={handleFilterModalClose}
          handleDeleteFilter={handleDeleteFilter}
          handleFilter={handleFilter} // Usa la nueva función de manejo de filtro
        >
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <DateInput
              key={filterKey}
              name="fecha"
              textLabel="Selecionar rango"
              classNameInput={styleForm.input__input}
              initialValue={[startDate, endDate]}
              range={true}
              dateFormat="dd-MM-yyyy"
              minDate={startDate ? new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() - 6)) : undefined}
              maxDate={startDate ? new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + 6)) : undefined}
              onChange={(dateSelected: any) => {
                setStartDate(dateSelected[0]);
                setEndate(dateSelected[1]);
              }}
            />
            <div></div>
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 1px" }}
          >
            <InputSearch
              key={Math.random()}
              serviceCall={serviceCallHoursBlock}
              onSelect={selected =>
                setHoursBlockStart({
                  label: selected.label,
                  value: selected.value
                })
              }
              selected={hoursBlockStart}
              name="startHoursBlock"
              textLabel="Bloque inicio"
              classNameInput={styleForm.input__input}
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", }}
          >

            <InputSearch
              key={Math.random()}
              serviceCall={serviceCallHoursBlockEnd}
              onSelect={setHoursBlockEnd}
              selected={hoursBlockEnd}
              name="endHoursBlock"
              textLabel="Bloque fin"
              classNameInput={styleForm.input__input}
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 10px" }}
          >

            <InputSearchFilter
              name="searchNrc"
              placeholder="Buscar por NRC"
              label="Buscar por NRC"
              value={searchNrc}  // Pasa el valor del estado como prop
              onSearchChange={(value) => setSearchNrc(value)}  // Mantiene la funcionalidad del callback
              classNameInput={styleForm.input__input}
            />
          </div>

          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 10px" }}
          >

            <InputSearchFilter
              name="searchPeriodId"
              placeholder="Buscar por id de período"
              label="Buscar  por id de período"
              value={termCodes}  // Pasa el valor del estado como prop
              onSearchChange={(value) => setTermCodes(value)}  // Mantiene la funcionalidad del callback
              classNameInput={styleForm.input__input}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 10px" }}
          >

            <InputSearchFilter
              name="searchCourse"
              placeholder="Buscar por código o nombre de curso"
              label="Buscar por código o nombre de curso"
              value={course}  // Pasa el valor del estado como prop
              onSearchChange={(value) => setCourse(value)}  // Mantiene la funcionalidad del callback
              classNameInput={styleForm.input__input}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 10px" }}
          >

            <InputSearchFilter
              name="searchTeacher"
              placeholder="Buscar por rut o nombre de profesor"
              label="Buscar por rut o nombre de profesor"
              value={teacher}  // Pasa el valor del estado como prop
              onSearchChange={(value) => setTeacher(value)}  // Mantiene la funcionalidad del callback
              classNameInput={styleForm.input__input}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 10px" }}
          >

            <InputSearchFilter
              name="searchRoom"
              placeholder="Buscar por Salón"
              label="Buscar por Salón"
              value={room}  // Pasa el valor del estado como prop
              onSearchChange={(value) => setRoom(value)}  // Mantiene la funcionalidad del callback
              classNameInput={styleForm.input__input}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "100%" }}
          >
            <AppInputSearchFilter
              key={Math.random()}
              name="buildingId"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona edificios*"
              serviceCall={serviceCallBuildingFilter}
              onSelect={(selected) => {
                setBuildingIdFilter(selected);
              }}
              selected={buildingIdFilter}
              multiple={true}
              showSelectAll={true}

            />
          </div>
          {warningMessage && (

            <div
              style={{
                backgroundColor: '#FFF3CD', // Fondo de advertencia amarillo claro
                color: '#856404', // Color del texto de advertencia (marrón)
                border: '1px solid #ffeeba', // Borde de advertencia
                padding: '10px', // Espacio interno
                borderRadius: '5px', // Bordes redondeados
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '10px', // Separación superior
                width: '100%'
              }}
            >
              <AppGetIcon name="alert" fillColor="#856404" />
              <span style={{ fontWeight: 'bold' }}>
                {warningMessage}
              </span>
            </div>



          )}
        </ConatinerFilter>
      </DataTable>
    </div>
  );
}
