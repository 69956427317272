
import { useCallback, useEffect, useState } from "react";
import { useUserStore } from "../../../../store/user.store";
import { useInitDataStore } from "../../../../store/initData.store";

import useFormattedDate from "../../../../common/hooks/useFormattedDate";
import { Field, Formik, Form } from "formik";
import * as yup from "yup";
import { shallow } from "zustand/shallow";
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import InputDateFormik from '../../../../common/UI/Inputs/InputDateFormik';
import Modal from "../../../../common/UI/Modal";
import DateInput from "../../../../common/UI/Inputs/InputDate";
import ConatinerFilter from "../../../../common/UI/DataTable/filterModal/containerFilter";
import DataTable from "../../../../common/UI/DataTable/dataTable";
import { InputText } from "../../../../common/UI/Inputs";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";

import style from "./CampusReservationLimit.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";
import styleDataTable from "../../../../common/UI/DataTable/dataTable.module.css";

import { GetCampusReservationLimitWithPaginationService } from "../../services/CampusReservationLimit/getCampusReservationLimitWithPagination.service";
import { CreateOrUpdateCampusReservationLimitService, IReservationsLimit } from "../../services/CampusReservationLimit/createOrUpdateCampusReservationLimit.service";
import { DeleteCampusReservationLimitService } from "../../services/CampusReservationLimit/deleteCampusReservationLimit.service";
import { DataSelectDto } from "../../../../common/dtos/general.dto";
import { GetCampusWithPaginationService } from "../../services/getCampusWithPagination.service";
import usePermission from "../../../../common/hooks/usePermission";
import { toast } from "react-toastify";

const getCampusReservationLimitWithPaginationService = new GetCampusReservationLimitWithPaginationService();
const createOrUpdateCampusReservationLimitService = new CreateOrUpdateCampusReservationLimitService();
const deleteCampusReservationLimitService = new DeleteCampusReservationLimitService();

export default function CampusReservationLimitPage() {
  const hFormatDate = useFormattedDate();
  const canCreateLimitCampus = usePermission('administration.booking.limit.campus', 'create');
  const canEditLimitCampus = usePermission('administration.booking.limit.campus', 'edit');
  const canDeleteLimitCampus = usePermission('administration.booking.limit.campus', 'delete');
  const canListLimitCampus = usePermission('administration.booking.limit.campus', 'list');
  const dataInitDataStore = useInitDataStore(
    (state) => ({
      userRoles: state.initData.userRoles.map(item => ({ value: String(item.roleId), label: item.role.name })),
      rolSelected: state.rolSelected,
      setItemRolePermissionId: state.setItemRolePermissionId,
      setRolSelected: state.setRolSelected,
      userCampus: state.initData?.userCampus ?? [].map((item: any) => ({
        campusId: String(item.campusId),
        campusName: String(item.campusName)
      })),
    }),
    shallow
  );
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [isReloadData, setIsReloadData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isOpenCurrentDelete, setIsOpenCurrentDelete] = useState<any>(null);
  const [isHoveredEdit, setIsHoveredEdit] = useState<number | null>(null);
  const [isHoveredDelete, setIsHoveredDelete] = useState<number | null>(null);
  const [initialData, setInitialData] = useState<IReservationsLimit | null>(null);
  const [startDate, setStartDate] = useState<any>(startOfMonth);
  const [endDate, setEndate] = useState<any>(endOfMonth);
  const [statusFilter, setStatusFilter] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const [campus, setCampus] = useState<DataSelectDto>({
    label: '',
    value: '',
  });
  const [campusFilter, setCampusFilter] = useState<DataSelectDto>({
    label: '',
    value: ''
  });

  const dataUserStore = useUserStore(
    state => ({
      userId: state.dataUser
    }),
    shallow
  );
  const getStatusOptions = () => {
    return [
      { label: "Activo", value: "1" },
      { label: "Inactivo", value: "0" },
    ];
  };
  const serviceCallCampus = useCallback(async (value: string) => {
    try {
      const response: any = await GetCampusWithPaginationService({
        search: value,
        perPage: 100
      });
      if (dataInitDataStore.rolSelected === 51) {
        const filteredCampus = response.data.filter((campus: any) =>
          dataInitDataStore.userCampus.some((userCampus: any) => String(userCampus.campusId) === String(campus.campusId))
        );
        return filteredCampus.map((item: any) => ({

          label: item.campusId, // Asegúrate de que este campo exista en tu respuesta
          value: String(item.campusId),
        }));
      } else {
        return response.data.map((item: any) => ({
          label: item.campusId,
          value: item.campusId,
        }));
      }

    } catch (error) {
      console.error("Error fetching campus:", error);
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInitDataStore.userCampus]);
  const handleDelete = async () => {
    setLoading(true);
    let params: any = {
      campusReservationLimitId: isOpenCurrentDelete?.id,
    };
    try {
      await updateGlobalState(canDeleteLimitCampus[1]);
      const response: any = await deleteCampusReservationLimitService.run(params); // Llama al servicio de eliminación con el ID del elemento seleccionado
      await updateGlobalState(canListLimitCampus[1]);
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="checkCircleFill" />
          Operación realizada correctamente
        </div>,
        { icon: false }
      );
      setIsReloadData(!isReloadData); // Recarga los datos después de la eliminación
      setIsOpenCurrentDelete(null); // Cierra el modal
      setLoading(false);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message ;
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="xCircleFill" />
          {errorMessage}
        </div>,
        { icon: false }
      );
      console.error("Error al eliminar la reserva:", error);
      setLoading(false);
    }
  };
  
  const handleCloseModalDelete = () => {
    setInitialData(null);
    setIsOpenCurrentDelete(null);
  };
  const columns = [
    {
      header: "NOMBRE",
      field: "name",
    },
    {
      header: "CAMPUS",
      field: "campusName",
    },
    {
      header: "FECHA INICIO",
      field: "",
      render: (row: any) => {
        return (
          <>
            {row.startDate ? (
              <div>{hFormatDate(row.startDate, "dd-MM-yyyy")}</div>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      header: "FECHA FIN",
      field: "",
      render: (row: any) => {
        return (
          <>
            {row.endDate ? (
              <div>{hFormatDate(row.endDate, "dd-MM-yyyy")}</div>
            ) : (
              ""
            )}
          </>
        );
      },
    },

    {
      header: "ESTADO",
      field: "active",
      render: (row: any) => {
        return row.active === 1 ? 'Activo' : 'Inactivo';
      },
    },
    {
      header: "OPCIONES",
      field: "",
      render: (row: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              gap: "5px",
            }}
          >
  {canEditLimitCampus && (

            <button
              className={styleDataTable.buttom_option}
              onClick={() => {
                handleEditUser(row);
              }}
              onMouseEnter={(e) => { setIsHoveredEdit(row?.id); }}
              onMouseLeave={(e) => { setIsHoveredEdit(null); }}
            >
              <AppGetIcon classIcon={styleDataTable.sizeIcon} name="edit" strokeColor="#962330" hoverColor={isHoveredEdit === row.id ? '#FFF' : undefined} /> Editar
            </button>

)}

            {canDeleteLimitCampus && (
            <button
              className={styleDataTable.buttom_option}
              onClick={() => {setIsOpenCurrentDelete(row);
              }}
              onMouseEnter={(e) => { setIsHoveredDelete(row?.id); }}
              onMouseLeave={(e) => { setIsHoveredDelete(null); }}
            >
              <AppGetIcon
                name="trash"
                classIcon={styleDataTable.sizeIcon}
                strokeColor="#962330"
                hoverColor={isHoveredDelete === row?.id ? "#FFF" : ""}
              />{" "}
              Eliminar
            </button>
            )}
          </div>
        );
      },
    },
  ];
  const reservationLimitSchema = yup.object().shape({
    name: yup.string().required("Nombre es requerido"),
    active: yup.string().required("Estado es requerido"),
    campusId: yup.string().required("Campus es requerido"),
    startDate: yup.string().required("Fecha inicio es requerido"),
    endDate: yup.string().required("Fecha fin es requerido"),
  });
  const handleSubmit = async (values: IReservationsLimit, actions: any) => {
    if (actions.errors && Object.keys(actions.errors).length !== 0) {
      actions.setSubmitting(false);
      return;
    }
    // Convertir las fechas a "yyyy-MM-dd" antes de enviarlas al backend
    const formattedStartDate = hFormatDate(values.startDate, "yyyy-MM-dd");
    const formattedEndDate = hFormatDate(values.endDate, "yyyy-MM-dd");
    let dataCreated: any = {
      ...values,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      active: values.active ? 1 : 0,
      creatorUserId: dataUserStore.userId.id,
      campusName: values.campusId
    };
    try {
      await updateGlobalState(values.id? canEditLimitCampus[1] : canCreateLimitCampus[1] );
      await createOrUpdateCampusReservationLimitService.run(dataCreated);
      await updateGlobalState(canListLimitCampus[1]);
      setInitialData(null);
      setIsReloadData(!isReloadData);
      setCampus({
        label: '',
        value: ''
      });
      setIsOpen(false);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message ;
    toast.error(
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <AppGetIcon name="xCircleFill" />
        {errorMessage?? 'Ocurrio un error, contactese con el administrador'}
      </div>,
      { icon: false }
    );
      console.error("Error guardando el recurso", error);
    }
  };
  const handleCloseModal = () => {
    setInitialData(null);  // Limpia los datos iniciales
    setIsOpen(false);  // Cierra el modal
    setCampus({
      label: '',
      value: ''
    });
  };
  const handleEditUser = async (row: IReservationsLimit) => {
    const formattedStartDate = hFormatDate(row?.startDate, "dd/MM/yyyy");
    const formattedEndDate = hFormatDate(row?.endDate, "dd/MM/yyyy");
    setCampus({
      label: row?.campusId ?? '',
      value: row?.campusId ?? ''
    });
    setInitialData({
      id: row?.id ?? null,
      name: row?.name ?? '',
      active: row?.active ? true : false,
      campusId: row?.campusId ?? null,
      campusName: row?.campusName ?? null,
      creatorUserId: row?.creatorUserId ?? null,
      startDate: formattedStartDate ?? '',
      endDate: formattedEndDate ?? ''
    });
    setIsOpen(true);
  };
  const handleFilterButtonClick = () => {
    setShowFilterModal(true);
  };
  const handleFilterModalClose = () => {
    setShowFilterModal(false);
  };
  const [filterKey, setFilterKey] = useState(0); // Estado para forzar la actualización de DateInput
  const handleDeleteFilter = () => {
    setStartDate(null);
    setEndate(null);
    setFilterKey((prevKey) => prevKey + 1); // Forzar la actualización de DateInput
  };
  const addButton = async () => {
    setIsOpen(true);
  };
  useEffect(() => {
    updateGlobalState(canListLimitCampus[1]);
    setIsReloadData(!isReloadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, statusFilter, campusFilter.value]);
  const updateGlobalState = (itemRolePermissionId: number) => {
    dataInitDataStore.setItemRolePermissionId(itemRolePermissionId);
	};
  return (
    <div className={style.containerQr}>
      <DataTable
        title="Limitaciones por Campus"
        columns={columns}
        service={getCampusReservationLimitWithPaginationService}
        params={{
          startDate: startDate ? hFormatDate(startDate, "yyyy-MM-dd") : "",
          endDate: endDate ? hFormatDate(endDate, "yyyy-MM-dd") : "",
          active: statusFilter.value,
          campusId: campusFilter.value,
        }}
        reloadData={isReloadData}
        addButton={canCreateLimitCampus ? () => {
          setIsOpen(true);
        } : undefined}
      >
        <ConatinerFilter
          nameIcon="filter"
          onClick={() => handleFilterButtonClick()}
          isOpen={showFilterModal}
          onClose={handleFilterModalClose}
          handleDeleteFilter={handleDeleteFilter}
        >
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <DateInput
              key={filterKey} // Utiliza la clave para forzar la actualización del componente
              name="fecha"
              textLabel="Selecionar rango"
              classNameInput={styleForm.input__input}
              initialValue={[startDate, endDate]}
              range={true}
              dateFormat="dd-MM-yyyy"
              onChange={(dateSelected: any) => {
                setStartDate(dateSelected[0]);
                setEndate(dateSelected[1]);
              }}
            />
            <div></div>
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="status"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona estado"
              data={getStatusOptions()}
              onSelect={(selected) => {
                setStatusFilter(selected);
              }}
              selected={statusFilter}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="campusId"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona sede/campus"
              serviceCall={serviceCallCampus}
              onSelect={(selected) => {
                setCampusFilter(selected);
              }}
              selected={campusFilter}  // Aquí pasamos el estado campus
            />
          </div>
        </ConatinerFilter>
      </DataTable>
      <Modal title={initialData?.id ? 'Editar limitación por  Campus' : `Crear limitación por Campus`} isOpen={isOpen} onClose={handleCloseModal} size={{ width: '600px', height: '' }} loadingModal={loading}
        footerContent={
          <div className={style.containerButtoms}>
            <button
              className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
              onClick={handleCloseModal}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className={styleForm.buttomFooter}
              form="limitCampusForm"
              disabled={loading}
            >
              Guardar
            </button>
          </div>
        }
      >
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
          <Formik
            key={initialData?.id ?? "new"}
            initialValues={{
              id: initialData?.id ?? null,
              name: initialData?.name ?? '',
              active: initialData?.active ? true : false,
              campusId: initialData?.campusId ?? '',  // Aquí se usa `campus.value`
              campusName: initialData?.campusId ?? '',  // Aquí se usa `campus.value`
              creatorUserId: initialData?.creatorUserId ?? null,
              startDate: hFormatDate(initialData?.startDate, "yyyy-MM-dd") ?? '',
              endDate: hFormatDate(initialData?.endDate, "yyyy-MM-dd") ?? '',
            }}
            validationSchema={reservationLimitSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, values, setFieldValue }) => (

              <Form id="limitCampusForm" className="flex-row" style={{ width: '100%' }}>
                <div className={style.form__container_row}>
                  <InputText
                    type="text"
                    classNameInput={styleForm.input__input}

                    name="name"
                    placeholder="Nombre"
                    TextLabel="Nombre"
                  />
                  <InputSearch
                    key={Math.random()}
                    name="campusId"
                    classNameInput={styleForm.input__input}
                    textLabel="Selecciona sede/campus"
                    serviceCall={serviceCallCampus}
                    onSelect={(selected) => {
                      setCampus({
                        label: selected.label,
                        value: selected.value
                      });  // Actualiza el estado `campus`
                      setFieldValue("campusId", selected.value);  // Actualiza el campusId en el formulario
                      setFieldValue("campusName", selected.label);  // Actualiza el campusName en el formulario
                    }}

                    selected={campus}  // Pasa el estado `campus` seleccionado
                  />
                </div>
                <div className={style.form__container_row}>
                  <InputDateFormik
                    TextLabel="Fecha inicio*"
                    name="startDate"
                    minDate={new Date()}
                    onChange={(date) => {
                      setFieldValue('startDate', date); // Actualizamos el campo `startDate`
                      setFieldValue('endDate', null);  // Reseteamos el campo `endDate`
                    }}
                  />
                  <InputDateFormik
                    TextLabel="Fecha fin*"
                    name="endDate"
                    minDate={new Date(values.startDate)}  // Calculamos 48 horas más a la fecha de inicioc
                  />
                </div>
                <div>
                  <label className={style.title}>Estado</label>
                  <Field
                    name="active"
                    render={({ field }: any) => (
                      <>
                        <input
                          {...field}
                          id="active"
                          type="checkbox"
                          checked={field.value}
                        />
                      </>
                    )}
                  />
                </div>

              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <Modal
        title={`Confirmación de Eliminación`}
        isOpen={isOpenCurrentDelete}
        onClose={handleCloseModalDelete}
        size={{ width: "600px", height: "" }}
        footerContent={
          <div className={style.containerButtoms}>
            <button
              className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
              onClick={handleCloseModalDelete}
            >
              Cancelar
            </button>
            <button
              type="button"
              className={styleForm.buttomFooter}
              onClick={handleDelete}
              disabled={loading}
            >
              Si, Eliminar
            </button>
          </div>
        }
      >
        <div style={{ padding: "30px" }}>
          <center>
            {`¿Estás seguro de que deseas eliminar la limitación por Campus ${isOpenCurrentDelete?.name}? Esta acción no se puede deshacer.`}
          </center>
        </div>
      </Modal>
    </div>
  );
}
