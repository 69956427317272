import { useMemo } from 'react';
import { ItemDto } from '../dtos/initData.dto';
import { useInitDataStore } from '../../store/initData.store';

const usePermission = (code: string, permiso: string) => {
  const { initData } = useInitDataStore((state) => state);

  // Función para encontrar un item por código de manera recursiva
  const findItemByCode = (items: ItemDto[], code: string): ItemDto | undefined => {
    for (const item of items) {
      if (item.code === code) return item;
      if (item.children && item.children.length > 0) {
        const found = findItemByCode(item.children, code);
        if (found) return found;
      }
    }
    return undefined;
  };

  // Memoriza el resultado de la búsqueda del permiso
  const hasPermission = useMemo(() => {
    const items = initData.items || [];  // Asegurarse de que initData.items existe o devolver una lista vacía
    const item = findItemByCode(items, code);

    if (item && item.rolePermissions) {
      const permissionType = item.rolePermissions[permiso];
      return permissionType ;
    }

    return false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData, code, permiso]);  // Solo se recalcula si initData, code o permiso cambian

  return hasPermission;
};

export default usePermission;
