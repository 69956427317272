import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import { useUserStore } from '../store/user.store';
import { eventBus } from '../common/services/eventBus';
import { ItemDto } from '../common/dtos/initData.dto';
import { useInitDataStore } from '../store/initData.store';
import { showToast } from '../common/UI/ToastNotification';

interface ProtectedRouteProps {
  redirectTo?: string;
  checkItems?: boolean;
  permissionCode?: string;
  requiredPermission?: string;
}

const ProtectedRoute: React.FC<React.PropsWithChildren<ProtectedRouteProps>> = ({
  children,
  redirectTo = "/login",
  checkItems = true,
  permissionCode = '',
  requiredPermission = 'list',
}) => {
  const { loading, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const dataUserStore = useUserStore((state) => ({
    updateDataUser: state.updateDataUser,
    clearStore: state.clearStore
  }));
  const toastShown = useRef(false);
  const { initData } = useInitDataStore((state) => state);

  // Verificación de autenticación y permisos
  useEffect(() => {
    // Esperar a que loading sea false antes de continuar
    if (loading) {
      console.log('Esperando a que termine la carga de autenticación...');
      return;
    }

    // Si no está autenticado, redirigir al login
    if (!isAuthenticated) {
      if (!toastShown.current) {
        showToast('No Autorizado.', 'warning');
        toastShown.current = true;
      }
      navigate(redirectTo, { replace: true });
      return;
    }

    // Verificar si initData está listo
    if (!initData || !initData.items || initData.items.length === 0) {
      console.log('Esperando a que los datos estén listos...');
      return;
    }

    // Si se requiere verificar permisos
    if (checkItems && permissionCode) {
      const findItemByCode = (items: ItemDto[], code: string): ItemDto | undefined => {
        for (const item of items) {
          if (item.code === code) return item;
          if (item.children && item.children.length > 0) {
            const found = findItemByCode(item.children, code);
            if (found) return found;
          }
        }
        return undefined;
      };

      const items = initData.items || [];
      const item = findItemByCode(items, permissionCode);


      if (item && item.rolePermissions) {
        const permissionType = item.rolePermissions[requiredPermission];

        if (permissionType && permissionType.includes(`${requiredPermission}-yes`)) {
          return; // Permiso concedido, renderizamos el contenido
        } else {
        }
      } else {
      }

      // Si llega aquí, significa que no tiene permiso
      navigate('/not-authorized/login', { replace: true });
    }

    const handleAuthTokenExpired = (message: any) => {
      if (!toastShown.current) {
        dataUserStore.clearStore();
        showToast(message, 'warning');
        setTimeout(() => {
          navigate(redirectTo);
        }, 6000);
        toastShown.current = true;
      }
    };

    const handleNetworkError = (message: any) => {
      showToast(message, 'error');
    };

    eventBus.on('authTokenExpired', handleAuthTokenExpired);
    eventBus.on('networkError', handleNetworkError);

    return () => {
      eventBus.off('authTokenExpired', handleAuthTokenExpired);
      eventBus.off('networkError', handleNetworkError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkItems, isAuthenticated, loading, initData, permissionCode, requiredPermission, navigate, showToast, redirectTo, dataUserStore]);

  // Si está cargando, no renderizar el contenido
  if (loading) {
    return null; // O puedes retornar un spinner de carga
  }

  // Si no está autenticado, no renderizar el contenido
  if (!isAuthenticated) {
    return null;
  }

  // Si initData aún no está listo, no renderizar el contenido
  if (!initData || !initData.items || initData.items.length === 0) {
    return null;
  }

  // Si todo está listo, renderizar el contenido
  return <>{children}</>;
};

export default ProtectedRoute;
