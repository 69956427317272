
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AppGetIcon from "../../../../common/UI/AppGetIcon";
import Modal from "../../../../common/UI/Modal/index";
import ConatinerFilter from "../../../../common/UI/DataTable/filterModal/containerFilter";
import DataTable from "../../../../common/UI/DataTable/dataTable";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";

import { DataSelectDto } from "../../../../common/dtos/general.dto";

import style from "./AdministrationRotationScreen.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";
import styleButton from "../../../../common/theme/styles/button.module.css";

import { GetAdministrationRotationScreenWithPaginationService } from "../../services/AdministrationRotationScreen/getAdministrationRotationScreenWithPagination.service";
import { GetCampusWithPaginationService } from "../../services/getCampusWithPagination.service";
import { DeleteAdministrationRotationScreenService, } from "../../services/AdministrationRotationScreen/deleteAdministrationRotationScreen.service";
import usePermission from "../../../../common/hooks/usePermission";
import { useInitDataStore } from "../../../../store/initData.store";
import { shallow } from "zustand/shallow";
import { toast } from "react-toastify";

const getAdministrationRotationScreenWithPaginationService = new GetAdministrationRotationScreenWithPaginationService();
const deleteAdministrationRotationScreenService = new DeleteAdministrationRotationScreenService();

export default function AdministrationRotationScreenPage() {
  const navigate = useNavigate();

  const canCreateRotationScreen = usePermission('rotationScreen.administration', 'create');
  const canEditRotationScreen = usePermission('rotationScreen.administration', 'edit');
  const canDeleteRotationScreen = usePermission('rotationScreen.administration', 'delete');
  const canListRotationScreen = usePermission('rotationScreen.administration', 'list');
 const dataInitDataStore = useInitDataStore(
      (state) => ({
        setItemRolePermissionId: state.setItemRolePermissionId,
      }),
      shallow
    );
  const [loading, setLoading] = useState(false);
  const [isReloadData, setIsReloadData] = useState(false);

  const [isHoveredEdit, setIsHoveredEdit] = useState<number | null>(null);
  const [isHoveredDelete, setIsHoveredDelete] = useState<number | null>(null);

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isOpenCurrentDelete, setIsOpenCurrentDelete] = useState<any>(null);

  const [statusFilter, setStatusFilter] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const [campusFilter, setCampusFilter] = useState<DataSelectDto>({
    label: '',
    value: ''
  });

  const columns = [
    {
      header: "NOMBRE",
      field: "name",
      render: (row: any) => {
        return (
          <div style={{ width: '130px' }}>
            {row.name}
          </div>
        )
      }
    },
    {
      header: "URL",
      field: "url",
      render: (row: any) => {
        return (
          <div style={{ width: '200px' }}>
            <a
              href={row.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styleForm.clickableText}
            >
              <AppGetIcon name="eye" fillColor="#5A5F65" /> Previsualizar pantalla
            </a>
          </div>
        );
      },
    },
    {
      header: "CAMPUS",
      field: "campusId",
    },
    {
      header: "EDIFICIOS",
      field: "buildingIds",
    },
    {
      header: "MOSTRAR PUBLICIDAD",
      field: "showAdvertising",
      render: (row: any) => {
        return row.showAdvertising === 1 ? (
          <span style={{ color: "#00FF00", textShadow: "0 0 2px #00FF00, 0 0 5px #00FF00" }}>✔️</span>
        ) : (
          <span style={{ color: "red", fontWeight: "bold", fontSize: "1em" }}>❌</span>
        );
      },
    },
    {
      header: "ARCHIVOS ASOCIADOS",
      field: "showAdvertising",
      render: (row: any) => {
        return (
          <div>{row?.files?.length}</div>
        )
      },
    },
    {
      header: "CREADO POR",
      field: "createdByFullname",
    },
    {
      header: "EDITADO POR",
      field: "updatedById",
    },

    {
      header: "OPCIONES",
      field: "",
      render: (row: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              gap: "5px",
            }}
          >
            {canEditRotationScreen && (
              <button
                className={styleButton.Buttom_option}
                onMouseEnter={(e) => { setIsHoveredEdit(row?.id); }}
                onMouseLeave={(e) => { setIsHoveredEdit(null); }}
                onClick={() => {navigate(`/booking/formAdministrationRotativeScreen/rotativeScreenId/${row.id}`)}}
              >
                <AppGetIcon
                  name="pencil"
                  classIcon={styleButton.SizeIcon}
                  strokeColor="#962330"
                  hoverColor={isHoveredEdit === row?.id ? "#FFF" : ""}
                />
                Editar
              </button>
            )}
            {canDeleteRotationScreen && (
              <button
                className={styleButton.Buttom_option}
                onClick={() => {setIsOpenCurrentDelete(row);
                }}
                onMouseEnter={(e) => { setIsHoveredDelete(row?.id); }}
                onMouseLeave={(e) => { setIsHoveredDelete(null); }}
              >
                <AppGetIcon
                  name="trash"
                  classIcon={styleButton.SizeIcon}
                  strokeColor="#962330"
                  hoverColor={isHoveredDelete === row?.id ? "#FFF" : ""}
                />
                Eliminar
              </button>
            )}

          </div>
        );
      },
    },
  ];

  const getStatusOptions = () => {
    return [
      { label: "Activo", value: "1" },
      { label: "Inactivo", value: "0" },
    ];
  };

  const serviceCallCampus = useCallback(async (value: string) => {
    const response: any = await GetCampusWithPaginationService({
      search: value,
      perPage: 100
    });
    return response.data.map((item: any) => ({
      label: item.campusId,
      value: item.campusId,
    }));
  }, []);

  const addButton = async () => {
    navigate("/booking/formAdministrationRotativeScreen/rotativeScreenId");
  };

  const handleDelete = async () => {
    setLoading(true);
    let params: any = {
      ScreensId: isOpenCurrentDelete?.id
    };
    try {
      updateGlobalState(canDeleteRotationScreen[1]);
      const response: any = await deleteAdministrationRotationScreenService.run(params);
      updateGlobalState(canListRotationScreen[1]);
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="checkCircleFill" />
          Operación realizada correctamente
        </div>,
        { icon: false }
      );
      setIsReloadData(!isReloadData);
      setIsOpenCurrentDelete(null);
      setLoading(false);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message ;
    toast.error(
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <AppGetIcon name="xCircleFill" />
        {errorMessage}
      </div>,
      { icon: false }
    );
      console.error("Error al eliminar la reserva:", error);
      setLoading(false);
    };
  };

  useEffect(() => {
    setIsReloadData(!isReloadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, campusFilter.value]);
  const updateGlobalState = (itemRolePermissionId: number) => {
    dataInitDataStore.setItemRolePermissionId(itemRolePermissionId);
	};
  return (
    <div className={style.containerQr}>
      <DataTable
        title="Administración de pantallas"
        columns={columns}
        service={getAdministrationRotationScreenWithPaginationService}
        params={{
          // active: statusFilter.value,
          campusId: campusFilter.value,
          sort: 'id'
        }}
        reloadData={isReloadData}
        addButton={canCreateRotationScreen ? () => {
            addButton();
        } : undefined}
      >
        <ConatinerFilter
          nameIcon="filter"
          onClick={() => setShowFilterModal(true)}
          isOpen={showFilterModal}
          onClose={() => setShowFilterModal(false)}
        >
          {/* <div className={style.container__select} style={{ minWidth: "280px" }}  >
            <InputSearch
              key={Math.random()}
              name="status"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona estado"
              data={getStatusOptions()}
              onSelect={(selected) => {
                setStatusFilter(selected);
              }}
              selected={statusFilter}
            />
          </div> */}
          <div className={style.container__select} style={{ minWidth: "280px" }}  >
            <InputSearch
              key={Math.random()}
              name="campusId"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona sede/campus"
              serviceCall={serviceCallCampus}
              onSelect={(selected) => {
                setCampusFilter(selected);
              }}
              selected={campusFilter}
            />
          </div>
        </ConatinerFilter>
      </DataTable>

      <Modal
        title={`Confirmación de eliminación`}
        isOpen={isOpenCurrentDelete}
        onClose={() => setIsOpenCurrentDelete(null)}
        size={{ width: "60%", height: "" }}
        loadingModal={loading}
        footerContent={
          <div className={styleButton.Container__buttoms_modal}>
            {/* TODO: sacar estos botonos a componentes y ponerle su icono*/}
            <button
              className={`${styleButton.Buttom__modal_cancel}`}
              onClick={() => setIsOpenCurrentDelete(null)}
            >
              Cancelar
            </button>
            <button
              type="button"
              className={styleButton.Buttom__modal_save}
              onClick={handleDelete}
              disabled={loading}
            >
              Si, Eliminar
            </button>
          </div>
        }
      >
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: "30px" }}>
          <AppGetIcon name='infoCircle' />
          <div style={{ textAlign: 'center' }}>
            {`¿Estás seguro de que deseas eliminar la limitación por Campus ${isOpenCurrentDelete?.name}? Esta acción no se puede deshacer.`}
          </div>
        </div>
      </Modal>
    </div>
  );
}
