import { services } from "../../../../constants/services";
import { getByQuery } from "../../../../utils/api";
import { ResponseQ } from "../../../../common/dtos/paginate.dto";

export class GetAttendanceTeacherWithPaginationService {
  async run(params: any) {
    const response = await getByQuery<ResponseQ<any>>(`${services.bookingReport}/getTeacherAttendanceHistory`, params);
    return response;
  };
}

