import './navBar.css';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { shallow } from 'zustand/shallow';
import { useInitDataStore } from '../../../store/initData.store';
import { useItemsStore } from '../../../store/items.store';
import { useAuthStore } from '../../../store/authStore.storage';
import { GetInitDataService } from '../../services/getInitData.service';
import SelectField from '../Inputs/inputSelectField';
import AppGetIcon from '../AppGetIcon';
// import { useAuth } from '../../../contexts/AuthContext';
// import AppDropdownSelect, { IOption } from './DropdownSelect';
import { IOption } from './DropdownSelect';
import ProgressBar from '../ProgressBar';
import { LogouOffice365Service } from '../../services/logoutOffice365.service';

interface NavBarProps {
  urlLogo: string;
  urlIcon: string;
}

const NavBar: React.FC<NavBarProps> = ({ urlLogo, urlIcon }) => {
  const API_ENDPOINT = process.env.REACT_APP_PUBLIC_BASE_URL_FRONT_QR;
  const REACT_APP_ENV_DEV = process.env.REACT_APP_ENV_DEV ?? '';


  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  // const { logout } = useAuth();

  const dataAuthStore = useAuthStore((state) => ({ clearToken: state.logout }), shallow);
  const dataItemsStorage = useItemsStore((state) => ({ items: state.items, setItems: state.setItems }), shallow);
  const dataInitDataStore = useInitDataStore(
    (state) => ({
      userRoles: state.initData.userRoles.map(item => ({ value: String(item.roleId), label: item.role.name })),
      rolSelected: state.rolSelected,
      setRolSelected: state.setRolSelected,
      userFaculty: state.initData?.userFaculty ?? [].map((item: IOption) => ({
        facultyCode: String(item.facultyCode),
        facultyName: String(item.facultyName)
      })),
      facultySelected: state.facultySelected,
      setFacultySelected: state.setFacultySelected,
      setInitData: state.setInitData,
    }),
    shallow
  );

  const signOut = async () => {
    setLoading(true);
    let response = await LogouOffice365Service();

    if (response.success) {
      // Elimina el token de localStorage
      localStorage.removeItem('token');

      // Limpia el token almacenado en Zustand
      dataAuthStore.clearToken();

      // Elimina todas las cookies relacionadas con la autenticación
      document.cookie = "authToken=; domain=.cyk.com.co; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure";
      document.cookie = "authUser=; domain=.cyk.com.co; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure";
      document.cookie = "authUserRoles=; domain=.cyk.com.co; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure";
      document.cookie = "rolSelected=; domain=.cyk.com.co; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure";
      document.cookie = 'isAuthenticated=; path=/; domain=.cyk.com.co; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure;';

      // Redirige al usuario a la página de logout de Microsoft y luego vuelve al login
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' + encodeURIComponent(`${API_ENDPOINT}/login`);

      setLoading(false);
    } else {
      console.log('Ocurrió un error al cerrar sesión');
      setLoading(false);
    }
  };


  const handleSelectRole = async (value: string) => {
    setLoading(true);

    // Actualizar el rol seleccionado en el store de Zustand
    dataInitDataStore.setRolSelected(Number(value));
    
    // Llamada al servicio para obtener nueva data con el rol seleccionado
    let response: any = await GetInitDataService(Number(value));
    if (response && response.items && response.items[0] && response.items[0].children) {
      // Actualiza Zustand stores
      await dataInitDataStore.setInitData(response);
      await dataItemsStorage.setItems(response.items);

      // Actualizar cookies con la nueva información
      const maxAge = 12 * 60 * 60; // 12 horas en segundos

      // Actualizar cookie para los datos del usuario
      const serializedUser = JSON.stringify(response.user);
      document.cookie = `authUser=${serializedUser}; domain=.cyk.com.co; path=/; Max-Age=${maxAge}; SameSite=None; Secure`;

      // Actualizar cookie para los roles del usuario
      const serializedRoles = JSON.stringify(response.userRoles);
      document.cookie = `authUserRoles=${serializedRoles}; domain=.cyk.com.co; path=/; Max-Age=${maxAge}; SameSite=None; Secure`;

      // Actualizar cookie para los campus del usuario
      const serializedUserCampus = JSON.stringify(response.userCampus);
      document.cookie = `authUserCampus=${serializedUserCampus}; domain=.cyk.com.co; path=/; Max-Age=${maxAge}; SameSite=None; Secure`;

      // **Nuevo: Actualizar cookie para rol seleccionado**
      document.cookie = `rolSelected=${value}; domain=.cyk.com.co; path=/; Max-Age=${maxAge}; SameSite=None; Secure`;

      // Redirección según los datos
      let domainUrl = response.items[0]?.children?.[0]?.domainUrl;
      let route = response.items[0]?.children?.[0]?.route;


      const baseApiUrl = process.env.REACT_APP_PUBLIC_BASE_API_URL;
      const baseQrUrl = process.env.REACT_APP_PUBLIC_BASE_API_QR_URL;

      const allowedDomains: any = [baseApiUrl, baseQrUrl];
      const isInternalDomain = domainUrl && allowedDomains.some((allowedDomain: any) =>
        domainUrl.startsWith(allowedDomain)
      );

      if (!REACT_APP_ENV_DEV && isInternalDomain) {
        const internalRoute = route.startsWith('/') ? route : `/${route}`;
        navigate(internalRoute, { replace: true });
      } else if (!REACT_APP_ENV_DEV && domainUrl && route) {
        window.location.href = `${domainUrl}${route}`;
      } else {
        window.location.href = `${response.items[0]?.children?.[0]?.domainUrl}${response.items[0]?.children?.[0]?.route}`;
        // navigate(response.items[0]?.children?.[0]?.route)
        console.error("Error: No se encontró un dominio o ruta válida. verifica");
      }
      setLoading(false);
    } else {
      console.error("Estructura de respuesta no válida", response);
      setLoading(false);
    }
  };



  // const handleSelectFaculty = async (value: string) => {
  //   dataInitDataStore.setFacultySelected(Number(value));
  //   let response: any = await GetInitDataService(Number(value));
  //   if (response && response.items && response.items[0] && response.items[0].children) {
  //     dataInitDataStore.setInitData(response);
  //     dataItemsStorage.setItems(response.items);
  //     navigate(response.items[0].children[0].route);
  //   } else {
  //     console.error("Invalid response structure", response);
  //   }
  // };
  return (
    <div className="containerNavBar">
      {loading && <div style={{ position: 'fixed', width: '100%', height: '100%' }}> <ProgressBar />   </div>}
      <div className="ContentLeft">
        <div className="AppNavbar__logo">
          <div>
            <img src={urlLogo} alt="logo universidad" />
          </div>
        </div>
      </div>
      <div className="ContentRight">
        {dataInitDataStore.userRoles.length > 0 && (
          <SelectField
            label=""
            classNameSelect="Select__roles"
            valueSelected={String(dataInitDataStore.rolSelected)}
            options={[...dataInitDataStore.userRoles]}
            handleChange={handleSelectRole}
          />
        )}
        <div className="profile" onClick={() => setActiveMenu(!activeMenu)}>
          <div className="img-box">
            <AppGetIcon name="userProfile" classIcon="userProfile_icon" />
          </div>
        </div>
        {activeMenu && <div className="overlay" onClick={() => setActiveMenu(!activeMenu)}></div>}
        <div className={`menu ${activeMenu ? 'active' : ''}`}>
          <ul>
            {/* {dataInitDataStore.userFaculty.length > 0 && (
              <li>
                <AppDropdownSelect
                  itemName="Cambiar de facultad"
                  options={dataInitDataStore.userFaculty}
                  modelValue={String(dataInitDataStore.facultySelected)}
                  onUpdate={handleSelectFaculty}
                />
              </li>
            )} */}
            {!loading ? (
              <li>
                <div className="itemMenu" onClick={signOut}>Cerrar sesión</div>
              </li>
            ) : (
              <li>
                <div className="itemMenu" >Cerrando sesión...</div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
