import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { eventBus } from '../common/services/eventBus';
import { showToast } from '../common/UI/ToastNotification';
import { IOption } from '../common/UI/Nabvar/DropdownSelect';
import { shallow } from 'zustand/shallow';
import { decrypt, encrypt } from './encrypt';
/**
 * Instancia de Axios configurada para realizar solicitudes a la API.
 * @type {AxiosInstance}
 */

 

const API_ENDPOINT = process.env.REACT_APP_PUBLIC_BASE_API_URL;

export const apiClient: AxiosInstance = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 100000,
  withCredentials:true
});

/**
 * Interceptor de Axios para agregar el token de autenticación al encabezado de las solicitudes.
 */
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  // Solo establecer 'Content-Type' si no está definido explícitamente
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }

  // Recuperar el initData-storage desde el almacenamiento local y parsearlo
  const initDataStorage = localStorage.getItem('initData-storage');
  if (initDataStorage) {
    try {
      const parsedData = JSON.parse(initDataStorage);
      const userId = parsedData?.state?.initData?.user?.id;
      
      const xcodex = {selectedRoleId: parsedData?.state?.rolSelected, itemRolePermissionId:parsedData?.state?.itemRolePermissionId};
      console.log("xcodex jopse",xcodex);
      const dataEncrypt = encrypt(xcodex);
      const userIdEncrypt = encrypt(userId);
      
      config.headers['xcodex'] = dataEncrypt;
      // Si el userId existe, agregarlo a los encabezados de la solicitud
      if (userId) {
        config.headers['userId'] = userIdEncrypt;
      }
    } catch (error) {
      console.error('Error parsing initData-storage:', error);
    }
  }
  return config;
});

/**
 * Interceptor de Axios para manejar respuestas y errores relacionados con la autenticación.
 */
apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: any) => {
    console.log('Error', error);

    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authToken');
      eventBus.emit('authTokenExpired', error.response.data?.message || "Tu sesión ha expirado, por favor inicia sesión nuevamente.");
    }
    if (error?.code === "ERR_NETWORK") {
      eventBus.emit('networkError', "Ha ocurrido un problema al obtener la información desde el servidor."); // Emitir evento para error de red
      // showToast('Ha ocurrido un problema al obtener la información desde el servidor.', 'error');
    }
    // if (error.response && error.response.status === 500) {
    //   console.log("error.response",error.response);
      
    //   showToast('Ocurrio un problema,contacta con el soporte.', 'error');
    // }
    return Promise.reject(error);
  }
);

/**
 * Obtiene todos los elementos de un endpoint específico.
 * @param {string} endpoint - El endpoint de la API.
 * @returns {Promise<T>} - Una promesa que resuelve con los datos obtenidos.
 * @template T
 */
export const getAll = async <T>(endpoint: string): Promise<T> => {
  try {
    const response = await apiClient.get<T>(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtiene un elemento por su ID de un endpoint específico.
 * @param {string} endpoint - El endpoint de la API.
 * @param {string | number} id - El ID del elemento.
 * @returns {Promise<T>} - Una promesa que resuelve con los datos obtenidos.
 * @template T
 */
export const getById = async <T>(endpoint: string, id: string | number): Promise<T> => {
  try {
    const response = await apiClient.get<T>(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtiene elementos de un endpoint específico con parámetros de consulta.
 * @param {string} endpoint - El endpoint de la API.
 * @param {object} queryParams - Parámetros de consulta para la solicitud.
 * @returns {Promise<T>} - Una promesa que resuelve con los datos obtenidos.
 * @template T
 */
export const getByQuery = async <T>(endpoint: string, queryParams: object): Promise<T> => {
  try {
    const response = await apiClient.get<T>(endpoint, { params: queryParams });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Crea un nuevo elemento en un endpoint específico.
 * @param {string} endpoint - El endpoint de la API.
 * @param {T} data - Los datos del elemento a crear.
 * @returns {Promise<T>} - Una promesa que resuelve con los datos del elemento creado.
 * @template T
 */
export const create = async <T>(endpoint: string, data: any): Promise<T> => {
  try {
    const response = await apiClient.post<T>(endpoint, data, {
      headers: {
        'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Actualiza un elemento por su ID en un endpoint específico.
 * @param {string} endpoint - El endpoint de la API.
 * @param {string | number} id - El ID del elemento a actualizar.
 * @param {T} data - Los datos actualizados del elemento.
 * @returns {Promise<T>} - Una promesa que resuelve con los datos del elemento actualizado.
 * @template T
 */
export const update = async <T>(endpoint: string, id: string | number, data: T): Promise<T> => {
  try {
    const response = await apiClient.put<T>(`${endpoint}/${id}`, data, {
      // headers: {
      //   'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
      // },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Elimina un elemento por su ID de un endpoint específico.
 * @param {string} endpoint - El endpoint de la API.
 * @param {string | number} id - El ID del elemento a eliminar.
 * @returns {Promise<void>} - Una promesa que resuelve cuando la eliminación es exitosa.
 */
export const remove = async (endpoint: string, id: string | number): Promise<void> => {
  try {
    await apiClient.delete(`${endpoint}/${id}`);
  } catch (error) {
    throw error;
  }
};

/**
 * Elimina un elemento enviando un cuerpo de solicitud a un endpoint específico.
 * @param {string} endpoint - El endpoint de la API.
 * @param {Object} body - El cuerpo de la solicitud que contiene los datos necesarios para la eliminación.
 * @returns {Promise<void>} - Una promesa que resuelve cuando la eliminación es exitosa.
 */
export const removeByBody = async (endpoint: string, body: object): Promise<void> => {
  try {
    await apiClient.delete(endpoint, { data: body });
  } catch (error) {
    throw error;
  }
};

/**
 * Elimina un elemento utilizando parámetros de consulta en la URL.
 * @param {string} endpoint - El endpoint de la API.
 * @param {object} queryParams - Parámetros de consulta para la solicitud.
 * @returns {Promise<void>} - Una promesa que resuelve cuando la eliminación es exitosa.
 */
export const removeWithQueryParams = async (endpoint: string, queryParams: object): Promise<void> => {
  try {
    await apiClient.delete(endpoint, { params: queryParams });
  } catch (error) {
    throw error;
  }
};

/**
 * Configura el token de autenticación en los encabezados de las solicitudes.
 * @param {string | null} token - El token de autenticación.
 */
export const setTokenInHeaders = (token: string | null) => {

  if (token) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete apiClient.defaults.headers.common["Authorization"];
  }
};
