export const services = {
  validations: process.env.REACT_APP_VALIDATIONS_SERVICE,
  users: process.env.REACT_APP_USERS_SERVICE,
  userCampus:process.env.REACT_APP_USER_CAMPUS_SERVICE,
  rooms: process.env.REACT_APP_ROOMS_SERVICE,
  admin: process.env.REACT_APP_ADMIN_SERVICE,
  attendance: process.env.REACT_APP_ATTENDANCE_SERVICE,
  qr: process.env.REACT_APP_QR_SERVICE,
  titled: process.env.REACT_APP_TITLED,
  resources: process.env.REACT_APP_RESOURCES_SERVICE,
  booking: process.env.REACT_APP_BOOKING_SERVICE,
  baseUrl: process.env.REACT_APP_PUBLIC_BASE_API_URL,
  generalReservationLimit: process.env.REACT_APP_GENERAL_RESERVATION_LIMIT_SERVICE,
  campusReservationLimit: process.env.REACT_APP_CAMPUS_RESERVATION_LIMIT_SERVICE,
  rotationScreen: process.env.REACT_APP_ROTATION_SCREEN_LIMIT_SERVICE,
  updatedClass: process.env.REACT_APP_UPDATED_CLASS_LIMIT_SERVICE,
  historicalAttendance: process.env.REACT_APP_HISTORICAL_ATTENDANCE_SERVICE,
  bookingReport: process.env.REACT_APP_BOOKING_REPORT_SERVICE,
  logBooking: process.env.REACT_APP_BOOKING_REPORT_LOGS_SERVICE,
  // ...otros servicios
};