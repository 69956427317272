import { services } from "../../../../constants/services";
import { getByQuery } from "../../../../utils/api";
import { IParamsReservationsLimit } from "../../../../common/dtos/paginate.dto";
import { decrypt, encrypt } from "../../../../utils/encrypt";

export class GetRotationDashboardScreenViewWithPaginationService {
  async run(params: IParamsReservationsLimit) {
    const response = await getByQuery<any>(`${services.rotationScreen}/getFullScreens`, params);
    let data = decrypt(response)
    
    let responseData = encrypt({
      data: data[0].classes?? [],
      page: data[0].page,
      perPage: data[0].perPage,
      total: data[0].total,
    })
    return responseData;
  };
}

