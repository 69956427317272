import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import style from './permissionRoles.module.css';
import styleForm from '../../../../common/theme/styles/form.module.css';

import { Formik, Form } from "formik";
import * as yup from "yup";

import { RolDto } from "../../dtos/rol.dto";
import CardRoles from "../../components/cardRoles";

import { GetRolesService } from "../../services/admin/permissionRoles/getRoles.service";
import { CreateOrUpdateRoleService } from "../../services/admin/permissionRoles/createOrUpdateRole.service";
import { InputText, InputTextArea } from "../../../../common/UI/Inputs";
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import { useHeaderStore } from "../../../../store/header.store";
import { shallow } from "zustand/shallow";
import ModalNew from "../../../../common/UI/Modal";
import ProgressBar from "../../../../common/UI/ProgressBar";
import usePermission from "../../../../common/hooks/usePermission";
import { useInitDataStore } from "../../../../store/initData.store";

const createOrUpdateRoleService = new CreateOrUpdateRoleService();
export default function Roles() {
  const navigate = useNavigate();
  const canCreateRole = usePermission('booking.roles', 'create');
  const canEditRole = usePermission('booking.roles', 'edit');
  const canListRole = usePermission('booking.roles', 'list');
 const dataInitDataStore = useInitDataStore(
      (state) => ({
        setItemRolePermissionId: state.setItemRolePermissionId,
      }),
      shallow
    );
  const [loading, setLoading] = useState<boolean>(true);
  const [rolesData, setRolesData] = useState<RolDto[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dataCreateRol, setDataCreateRol] = useState<RolDto>(
    {
      id: 0,
      name: '',
      description: ''
    }
  );
  const headerStore = useHeaderStore(
    (state) => ({
      setModule: state.setModule,
      setModuleItem: state.setModuleItem,
    }),
    shallow
  );
  useEffect(() => {
    updateGlobalState(canListRole[1]);
    headerStore.setModule('Administración', '');
    headerStore.setModuleItem('Roles', '/admin/roles');
    getDataRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataRoles = async () => {
    let response = await GetRolesService();
    setRolesData(response);
    setLoading(false);
  }
  const handleCreatedRole = () => {
    setDataCreateRol(
      {
        id: 0,
        name: '',
        description: ''
      }
    )
    setIsOpen(true);
  };
  const handleEdit = async (role: any) => {
    setDataCreateRol({
      id: role.id,
      name: role.name,
      description: role.description,
    })
    setIsOpen(true);
  };
  const handlePermissions = (role: any) => {
    updateGlobalState(canListRole[1]);
    navigate(`/admin/roles/permissions/role/${role.id}/${role.name}`);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDataCreateRol(
      {
        id: 0,
        name: '',
        description: ''
      }
    );
  };

  const RoleSchema = yup.object().shape({
    name: yup.string().required("nombre es requerido"),
    description: yup.string().required("La descripción es requerida"),
  });

  const handleSubmit = async (values: any, actions: any) => {
    if (actions.errors && Object.keys(actions.errors).length !== 0) {
      // El formulario tiene errores, no se envía la petición
      actions.setSubmitting(false);
      return;
    }
    setLoading(true);
    let dataCreatedRole = {

      name: values.name,
      description: values.description,
    }
    let idEditRol = dataCreateRol.id > 0 ? dataCreateRol.id : null;
    await handleCloseModal();
    updateGlobalState(values.id? canEditRole[1] : canCreateRole[1]);
    await createOrUpdateRoleService.run(dataCreatedRole, idEditRol);
    updateGlobalState(canListRole[1]);
    
    await getDataRoles();
    setLoading(false);
    actions.setSubmitting(false);
  };
  const updateGlobalState = (itemRolePermissionId: number) => {
    dataInitDataStore.setItemRolePermissionId(itemRolePermissionId);
	};
  return (
    <div className={loading ? style.containerRols + " " + style.containserLoading : style.containerRols}>
      {
        loading ? <ProgressBar width='calc(100% - 0px)' height='calc(100vh - 57px)' /> :
          <>
            {
              canCreateRole &&
              <div className={style.cardAdd} 
              onClick={() => {
                handleCreatedRole();
              }}
             >
                <AppGetIcon name="plus" strokeColor="#212529" /> Crear
              </div>
            }

            {
              rolesData && rolesData.map((role: RolDto, index: number) =>
                <CardRoles
                  key={index}
                  title={role.name}
                  titlePosition='start'
                  description={role.description}
                  backgroundColor='#042F53'
                  titleColor='#FFF'
                  handleEdit={canEditRole ? () => {
                    handleEdit(role);
                  } : () => {}}
                  handlePermissions={() => handlePermissions(role)}
                />
              )
            }
          </>
      }

      <ModalNew title={dataCreateRol.id ? `Editar Rol` : 'Crear Rol'} isOpen={isOpen} onClose={handleCloseModal} size={{ width: '500px', height: 'auto' }}
        footerContent={
          <Formik
            initialValues={{ name: dataCreateRol.name ?? '', description: dataCreateRol.description ?? '' }}
            validationSchema={RoleSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <div className={style.containerButtoms}>
                <button
                  className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
                  onClick={handleCloseModal}
                >
                  Cancelar
                </button>
                <button
                  form="activityForm"
                  type="submit"
                  className={styleForm.buttomFooter}
                  disabled={isSubmitting}
                >
                  Guardar
                </button>
              </div>
            )}
          </Formik>
        }
      >
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
          <Formik
            initialValues={{ name: dataCreateRol.name ?? '', description: dataCreateRol.description ?? '' }}
            validationSchema={RoleSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors }) => (
              <Form id="activityForm" className="flex-row" style={{ width: '100%' }}>
                <InputText
                  type="text"
                  classNameInput={styleForm.input__input}
                  name="name"
                  placeholder="Nombre rol"
                  TextLabel="Nombre"
                />
                <InputTextArea
                  name="description"
                  classNameInput={styleForm.input__input}

                  placeholder="Descripción rol"
                  TextLabel="Descripción"
                />

              </Form>
            )}
          </Formik>
        </div>
      </ModalNew>

    </div>

  )
}
