import React, { useState } from 'react';
import * as XLSX from 'sheetjs-style';

import { useSnackbar } from 'notistack';
import style from './dataTable.module.css';
import AppGetIcon from '../AppGetIcon';
import CardLoading from '../CardLoading';
import { toast } from 'react-toastify';



interface ExcelButtonProps {
  service: any;  // Aquí es donde esperamos la firma correcta
  params: any;
  enabled: boolean;
  nameFile: string;
  nameButton: string;
  getExcelData?: (data: any[]) => any[];
  hiddenColumns?: number[];
  lockedColumns?: number[];
  dateColumns?: { index: number; format: string }[];
  excelButton?: {
    enabled: boolean;
    nameFile: string;
    getExcelData: (data: any[]) => any[];
  };
  onClick?: () => void; // Propiedad opcional para manejar clics
}
const ExcelButton = ({ service, params, enabled, nameFile, nameButton = 'Excel', getExcelData, hiddenColumns, lockedColumns, dateColumns, onClick }: ExcelButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isProgressBar, setIsProgressBar] = useState<boolean>(false);
  const [isHoveredExcel, setIsHoveredExcel] = useState(false);

  const downloadExcel = async () => {
    if (!enabled) return;
  
    // Llama a la función `onClick` si está definida
    if (onClick) {
      onClick();
    }
  
    if ((!params.startTime && !params.endTime) || (params.startTime && params.endTime)) {
      // Continuar con la lógica
    } else {
      if (!params.startTime && params.endTime) {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <AppGetIcon name="xCircleFill" />
            El bloque de inicio es obligatorio si seleccionas un bloque de fin.
          </div>,
          { icon: false }
        );
      } else if (params.startTime && !params.endTime) {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <AppGetIcon name="xCircleFill" />
            El bloque de fin es obligatorio si seleccionas un bloque de inicio.
          </div>,
          { icon: false }
        );
      }
      return;
    }
  
    setIsProgressBar(true);
    try {
      const response = await service.run(params);
      let dataExcel = response;
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="checkCircleFill" />
          {response}
        </div>,
        { icon: false }
      );
      if (getExcelData) {
        dataExcel = getExcelData(dataExcel);
      }
  
      if (dataExcel.length > 0) {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(dataExcel, {
          cellDates: true,
          dateNF: 'dd-MM-yyyy',
        });
  
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        const url = window.URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${nameFile}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        enqueueSnackbar('Excel descargado correctamente.', { variant: 'success' });
      } else {
        enqueueSnackbar('No se encontró información para descargar', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Error al descargar el archivo', { variant: 'error' });
    } finally {
      setIsProgressBar(false);
    }
  };
  

  return (
    <div>
      <div
        className={style.buttom_option}
        onMouseEnter={() => setIsHoveredExcel(true)}
        onMouseLeave={() => setIsHoveredExcel(false)}
        onClick={downloadExcel}
      >
        <AppGetIcon
          name='excel'
          classIcon={style.sizeIcon}
          hoverColor={isHoveredExcel ? '#FFF' : undefined}
        />
        {nameButton}
      </div>
      {isProgressBar &&
        <div>
          <CardLoading nameIcon='excel' title={`Exportando ${nameFile}`} />
        </div>
      }
    </div>
  );
};

export default ExcelButton;
