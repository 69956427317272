import { useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import * as XLSX from 'xlsx';

import AppGetIcon from '../AppGetIcon';
import Search from './search/search';

import { ColumnProps, DataTableColumn } from './dataTableColumn';
import { useDataTableStore } from '../../../store/dataTable.store';

import { decrypt, encrypt } from '../../../utils/encrypt';

import style from './dataTable.module.css';
import { useSnackbar } from 'notistack';
import useDebounce from '../../hooks/useDebounce';
import ProgressLine from '../Loaders/ProgressLine';
interface ExcelButtonProps {
  enabled: boolean;
  nameFile: string;
  getExcelData?: (data: any[]) => any[];
  hiddenColumns?: number[]; // Array de índices de columnas a ocultar
  lockedColumns?: number[]; // Array de índices de columnas a bloquear
  dateColumns?: { index: number; format: string }[]; // Array de configuraciones de columnas de fecha

}
interface DataTableProps {
  title?: string;
  columns: ColumnProps[];
  service: any;
  params?: any;
  reloadData: boolean;
  selectedData?: any;
  addButton?: () => void;
  editButton?: () => void;
  excelButton?: ExcelButtonProps;
  moreActions?: React.ReactNode; // Prop adicional para controles de filtro u otros elementos

  onCellClick?: (rowIndex: number) => void;
  generateQr?: () => void;
  downloadQr?: () => void;
  checkAllId?: string;
  returnValue?: (props1: any, props2: any) => void;
  importExcel?: (value: any) => void | boolean;
  isEncryp?: boolean;
  filterCondition?: (item: any) => boolean;  // Nueva propiedad
  alertText?: string;
  searchText?: string;
  shouldCallService?: boolean;
  showSearch?: boolean; // Nuevo prop para controlar la visibilidad del buscador
};
interface DataPaginate {
  data: any[];
  page: number;
  perPage: number;
  total: number;
};

  const DataTable = ({ title, columns, service, reloadData, params, addButton, editButton, excelButton, moreActions, generateQr, downloadQr, checkAllId, children, returnValue, selectedData, importExcel, isEncryp, alertText='No se encontró información.', searchText= 'Buscar', filterCondition, shouldCallService = true , showSearch = true}: DataTableProps & { children: React.ReactNode }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dataTableStorage = useDataTableStore(
    (state) => ({
      updateDataTable: state.updateDataTable,
      updateSelectedAll: state.updateSelectedAll,
      updateSelectedData: state.updateSelectedData,
      dataTable: state.dataTable,
      selectedData: state.selectedData,
    }),
    shallow
  );

  const [isProgressBar, setIsProgressBar] = useState<boolean>(true);
  const [data, setData] = useState<DataPaginate>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<string>('10');
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredEdit, setIsHoveredEdit] = useState(false);
  const [isHoveredSearch, setIsHoveredSearch] = useState(false);
  const [isHoveredGenerate, setIsHoveredGenerate] = useState(false);
  const [isHoveredExcel, setIsHoveredExcel] = useState(false);
  const [isHoveredExcelImport, setIsHoveredExcelImport] = useState(false);
  const [isHoveredDownload, setIsHoveredDownload] = useState(false);
  const debouncedInputValue = useDebounce(searchTerm, 500);

  const tableRef = useRef(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!shouldCallService) {
      setIsProgressBar(false);

      return;
    }
  
    let isMounted = true; // Variable para controlar el montaje del componente

    const fetchData = async () => {
      setIsProgressBar(true);
      try {
        let paramsService = {
          
          ...params,
          page: currentPage,
          perPage: Number(perPage),
          search: searchTerm || '',
        };
        if (service) {
          const responseService = await service.run(isEncryp ? { params: encrypt(paramsService) } : paramsService);
          let response = isEncryp ? decrypt(responseService) : responseService

          if (isMounted) { // Solo actualizar el estado si el componente está montado
            setData({
              data: response.data.sort((a: any, b: any) => b.id - a.id),
              page: response.page ?? 1,
              perPage: response.perPage,
              total: response.total ?? 10,
            });

            if (returnValue) {
              returnValue(data, paramsService);
            }
            dataTableStorage.updateDataTable(response);
          }
        }
      } catch (error) {
        console.error(error);
        if (isMounted) { // Solo actualizar el estado si el componente está montado
          setData({
            data: [],
            page: 1,
            perPage: 10,
            total: 0,
          });
          dataTableStorage.updateDataTable({
            data: [],
            page: 1,
            perPage: 10,
            total: 0,
          });
        }
      } finally {
        if (isMounted) { // Solo actualizar el estado si el componente está montado
          setIsProgressBar(false);
          // dataTableStorage.updateSelectedData([]);
        }
      }
    };

    fetchData();

    // Función de limpieza que se ejecuta cuando el componente se desmonta
    return () => {
      isMounted = false;
      setData({
        data: [],
        page: 1,
        perPage: 10,
        total: 0,
      });
      dataTableStorage.updateDataTable({
        data: [],
        page: 1,
        perPage: 10,
        total: 0,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, debouncedInputValue, reloadData, shouldCallService]); // Lista de dependencias simplificada

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1)
  };
  const handleSearchClear = () => {
    setSearchTerm('');
  };
  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  useEffect(() => {
    setSelectedRow(selectedData)
  }, [selectedData])


  const downloadExcel = async () => {
    setIsProgressBar(true);
    let dataExcel: any = [];

    if (excelButton?.enabled) {
      if (dataTableStorage.selectedData.length > 0) {
        dataExcel = excelButton.getExcelData ? excelButton.getExcelData(dataTableStorage.selectedData) : dataTableStorage.selectedData;
      } else {
        try {
          const paramsService = {
            ...params,
            page: 1,
            perPage: Number(dataTableStorage.dataTable.total),
            search: searchTerm || '',
          };
          const responseService = await service.run(isEncryp ? { params: encrypt(paramsService) } : paramsService);

          let response = isEncryp ? decrypt(responseService) : responseService

          dataExcel = excelButton.getExcelData ? excelButton.getExcelData(response.data) : response.data;
        } catch (error) {
          enqueueSnackbar("No se encontró información para descargar", { variant: 'error' });
          console.log('error', error);
        }
      };
      if (dataExcel.length > 0) {
        // Lógica para generar y descargar el archivo Excel...
        // Crear un nuevo libro de trabajo
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(dataExcel, {
          cellDates: true,
          dateNF: 'dd-MM-yyyy' // Formato de fecha, puedes cambiarlo según necesites
        });
        // Verificar que '!ref' existe antes de proceder
        if (!worksheet['!ref']) {
          console.error('Error: El rango de la hoja de cálculo no está definido.');
          enqueueSnackbar("Error al generar el Excel: El rango de la hoja de cálculo no está definido.", { variant: 'error' });
          setIsProgressBar(false);
          return;
        }
        // Suponiendo que dateColumns es como [{ index: 27, format: 'dd-mm-yyyy' }]
        if (excelButton.dateColumns && excelButton.dateColumns.length > 0) {
          const range = XLSX.utils.decode_range(worksheet['!ref']);
          excelButton.dateColumns.forEach(dateCol => {
            for (let R = range.s.r; R <= range.e.r; ++R) {
              const cell_ref = XLSX.utils.encode_cell({ c: dateCol.index, r: R });
              if (worksheet[cell_ref]) {
                worksheet[cell_ref].z = dateCol.format;
              }
            }
          });
        }

        // Convertir los datos a un formato que xlsx pueda entender
        // const worksheet = XLSX.utils.json_to_sheet(dataExcel);

        // Configura las columnas ocultas basadas en la propiedad hiddenColumns
        if (excelButton.hiddenColumns && excelButton.hiddenColumns.length > 0) {
          worksheet['!cols'] = excelButton.hiddenColumns.map(index => ({
            hidden: true,
            wpx: index
          }));
        }


        // Configura las celdas bloqueadas
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        if (excelButton.lockedColumns && excelButton.lockedColumns.length > 0) {
          for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
              const cell_address = { c: C, r: R };
              const cell_ref = XLSX.utils.encode_cell(cell_address);
              if (excelButton.lockedColumns.includes(C)) {
                if (!worksheet[cell_ref]) worksheet[cell_ref] = {};
                worksheet[cell_ref].s = { protection: { locked: true } };
              }
            }
          }
        }

        // Proteger la hoja de cálculo
        // worksheet['!protect'] = {
        //   selectLockedCells: false, //true
        //   selectUnlockedCells: false, //true
        //   formatCells: false,
        //   formatColumns: false,
        //   formatRows: false,
        //   insertColumns: false,
        //   insertRows: false,
        //   insertHyperlinks: false,
        //   deleteColumns: false,
        //   deleteRows: false,
        //   sort: false,
        //   autoFilter: false,
        //   pivotTables: false,
        //   objects: false,
        //   scenarios: false,
        // };


        // Añadir la hoja de cálculo al libro con un nombre de hoja
        XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

        // Opciones para guardar el archivo
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        // Crear un enlace y descargar el archivo
        const url = window.URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${excelButton.nameFile}.xlsx`; // Nombre del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar("Excel descargado correctamente.", { variant: 'success' });
      }
      else {
        enqueueSnackbar("No se encontró información para descargar", { variant: 'error' });
      }
      setIsProgressBar(false);
    }
  };


  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && importExcel) {
      importExcel(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';  // Restablecer el valor del input de archivo
    }
  };
  useEffect(() => {
    return () => {
      dataTableStorage.updateSelectedData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ width: '100%' }}>
      <div className={style.table}>
        <section className={style.table__header}>
          <div className={style.header__title_table}>{title ?? ''}</div>
          <div className={style.header__options}>
            <div className={style.input_group}>
              {children} 
              {showSearch && (
  !openSearch ? (
    <div
      className={style.buttom_option}
      onClick={() => setOpenSearch(true)}
      onMouseEnter={() => setIsHoveredSearch(true)}
      onMouseLeave={() => setIsHoveredSearch(false)}
    >
      <AppGetIcon
        name="search"
        classIcon={style.sizeIcon}
        strokeColor="#962330"
        fillColor="#962330"
        hoverColor={isHoveredSearch ? '#FFF' : undefined}
      />
      {searchText}
    </div>
  ) : (
    <div>
      <Search
        onChange={handleSearchChange}
        onClear={handleSearchClear}
        closeSearch={handleSearchClose}
        valueInput={searchTerm}
        searchPlaceholder={searchText}
      />
    </div>
  )
)}

              {
                addButton &&
                <div
                  className={style.buttom_option}
                  onClick={addButton}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <AppGetIcon
                    name='plus'
                    classIcon={style.sizeIcon}
                    hoverColor={isHovered ? '#FFF' : undefined}
                  />
                  Crear
                </div>
              }
              {
                editButton &&
                <div
                  className={style.buttom_option}
                  onClick={editButton}
                  onMouseEnter={() => setIsHoveredEdit(true)}
                  onMouseLeave={() => setIsHoveredEdit(false)}
                >
                  <AppGetIcon
                    name='edit'
                    classIcon={style.sizeIcon}
                    hoverColor={isHoveredEdit ? '#FFF' : undefined}
                  />
                  Editar
                </div>
              }
              {
                downloadQr &&
                <div
                  className={style.buttom_option}
                  onClick={downloadQr}
                  onMouseEnter={() => setIsHoveredDownload(true)}
                  onMouseLeave={() => setIsHoveredDownload(false)}
                >
                  <AppGetIcon
                    name='download'
                    classIcon={style.sizeIcon}
                    hoverColor={isHoveredDownload ? '#FFF' : undefined}
                  />
                  Descargar códigos
                </div>
              }
              {
                excelButton &&
                <div
                  className={style.buttom_option}
                  onMouseEnter={() => setIsHoveredExcel(true)}
                  onMouseLeave={() => setIsHoveredExcel(false)}
                  onClick={downloadExcel}
                >
                  <AppGetIcon
                    name='excel'
                    classIcon={style.sizeIcon}
                    hoverColor={isHoveredExcel ? '#FFF' : undefined}
                  />
                  Excel
                </div>
              }
              {
                importExcel && (
                  <div
                    className={style.button_option}
                    onClick={triggerFileInput}
                    onMouseEnter={() => setIsHoveredExcelImport(true)}
                    onMouseLeave={() => setIsHoveredExcelImport(false)}
                  >
                    <AppGetIcon
                      name='excel'
                      classIcon={style.sizeIcon}
                      hoverColor={isHoveredExcelImport ? '#FFF' : undefined}
                    />
                    Importar
                    <input ref={fileInputRef} type="file" className={style.custom_file_input} id="customFile" onChange={handleFileInput} accept=".xls, .xlsx" style={{ display: 'none' }} />
                  </div>
                )}

              {
                generateQr &&
                <div
                  className={style.buttom_option}
                  onClick={generateQr}
                  onMouseEnter={() => setIsHoveredGenerate(true)}
                  onMouseLeave={() => setIsHoveredGenerate(false)}
                >
                  <AppGetIcon
                    name='qr'
                    classIcon={style.sizeIcon}
                    fillColor='#962330'
                    strokeColor='#962330'
                    hoverColor={isHoveredGenerate ? '#FFF' : undefined}
                  />
                  Generar nuevos códigos
                </div>
              }

              {
                moreActions
              }
            </div>
            <div className={style.pagination}>
              <button
                onClick={() => { setCurrentPage((prev) => prev - 1); dataTableStorage.updateSelectedAll(false) }}
                disabled={isProgressBar || currentPage === 1}
              >
                <AppGetIcon name='arrow-back' strokeColor='#3C4248' fillColor='none' />
              </button>
              <span>Página {currentPage}</span>
              <button
                onClick={() => { setCurrentPage((prev) => prev + 1); dataTableStorage.updateSelectedAll(false) }}
                disabled={(data?.total ? currentPage === Math.ceil(data?.total / Number(perPage)) : false) || isProgressBar}
              >
                <AppGetIcon name='arrow-next' strokeColor='#3C4248' fillColor='none' />
              </button>
              <select autoComplete="off" value={perPage} onChange={(e) => setPerPage(e.target.value)} disabled={isProgressBar}>
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='20'>20 </option>
                <option value='50'>50 </option>
              </select>
            </div>
          </div>
        </section>
        <section className={`${style.table__body} ${isProgressBar ? style.isProgress : ''}`}>

          <table ref={tableRef}>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <DataTableColumn key={index} {...column} background='bajo' checkAllId={checkAllId} filterCondition={filterCondition} />
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={columns.length} style={{ padding: '0px' }}>
                  {isProgressBar && <ProgressLine />}
                </td>
              </tr>

              {
                dataTableStorage.dataTable.data.map((item, index) => (
                  <tr key={index} className={selectedRow && selectedRow.includes(item) ? 'rowSelected' : ''}>
                    {columns.map((column, index) => (
                      <td key={index} >
                        {
                          column.render ? column.render(item, index) : item[column.field]
                        }
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
        <div className={style.table__footer}>
          {
            !isProgressBar && dataTableStorage.dataTable.data.length === 0 &&
            <div className={style.Container__notFound}>
              <div className={style.Container__notFound} ><AppGetIcon name='infoCircle' />  <span>{alertText}</span></div>
            </div>
          }
          <div>
            Mostrando 1 a {perPage} de {data?.total} registros
          </div>
        </div>
      </div>
    </div>

  );
};
export default DataTable;