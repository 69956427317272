import { useEffect, useRef, useState } from "react";
import style from './appInputSearch.module.css';
import { ErrorMessage, useField } from "formik";
import { DataSelectDto, InputComponentProps } from "../../../dtos/general.dto";
import useDebounce from "../../../hooks/useDebounce";
import AppGetIcon from "../../AppGetIcon";

const SELECT_ALL_VALUE = "ALL";

const AppInputSearch: React.FC<InputComponentProps> = ({
  name = '', classNameInput, textLabel, serviceCall, onSelect, selected, addString, textAddString = 'Seleccionar este líder', multiple = false, disabled, uploadExcel, labelExcel, showSelectAll = false, data = []
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState<DataSelectDto[]>(data);
  const [selectedValues, setSelectedValues] = useState<DataSelectDto[]>(Array.isArray(selected) ? selected : []);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [field, , { setValue }] = useField(name);
  const [isHoveredAdd, setisHoveredAdd] = useState(false);
  const [isHoveredTrash, setIsHoveredTrash] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, 500);
  const inputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!multiple && selected) {
      setInputValue(selected.label);
    } else if (multiple && Array.isArray(selected)) {
      setSelectedValues(selected);
    }
  }, [selected, multiple]);


  useEffect(() => {
    if (isExpanded) {
      fetchSearchData(debouncedInputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue]);

  const fetchInitialData = () => {
    if (serviceCall) {
      setIsLoading(true);
      serviceCall('')
        .then((data: any) => {
          setSelectValue(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setSelectValue(data);
    }
    setIsExpanded(true);
  };

  const fetchSearchData = (value: string) => {
    if (serviceCall) {
      setIsLoading(true);
      serviceCall(value)
        .then((data: any) => {
          setSelectValue(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      const filteredData = data.filter((item: any) => item.label.toLowerCase().includes(value.toLowerCase()));
      setSelectValue(filteredData);
    }
  };

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleOptionClick = (selected: DataSelectDto, isNewOption = false) => {
    if (multiple) {
      const isSelected = selectedValues.some(item => item.value === selected.value);
  
      if (isNewOption) {
        // Si es una nueva opción, agregar directamente al estado y al selectValue
        const newSelectedValues = [...selectedValues, selected];
        setSelectedValues(newSelectedValues);
        setValue(newSelectedValues.map(item => item));
        setInputValue(''); // Limpiar el input después de agregar
        return; // Salir, ya que no se necesita más lógica para nuevas opciones
      }
  
      if (selected.value === SELECT_ALL_VALUE) {
        // Manejar selección/deselección de todos
        const allSelected = selectValue.every(option =>
          selectedValues.some(selectedItem => selectedItem.value === option.value)
        );
  
        if (allSelected) {
          setSelectedValues([]);
          setValue([]);
        } else {
          const allValues = selectValue.map(item => ({ label: item.label, value: item.value }));
          setSelectedValues(allValues);
          setValue(allValues);
        }
      } else {
        if (isSelected) {
          const newSelectedValues = selectedValues.filter(item => item.value !== selected.value);
          setSelectedValues(newSelectedValues);
          setValue(newSelectedValues.map(item => item));
        } else {
          const newSelectedValues = [...selectedValues, selected];
          setSelectedValues(newSelectedValues);
          setValue(newSelectedValues.map(item => item));
        }
      }
  
      setInputValue('');
  
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      if (field.value === selected.value) {
        setValue('');
        setInputValue('');
        onSelect({ label: '', value: '' });
      } else {
        setValue(selected.value);
        setInputValue(selected.label);
        onSelect(selected);
      }
      setIsExpanded(false);
    }
  
    if (!selected.value) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  
    calculatePosition(); // Recalcula la posición después de seleccionar una opción
  };
  


  const handleRemoveChip = (removedValue: string) => {
    const newSelectedValues = selectedValues.filter(item => item.value !== removedValue);
    setSelectedValues(newSelectedValues);
    setValue(newSelectedValues.map(item => item));

    calculatePosition(); // Recalculate the position after removing a chip
  };

  const changeIsExpandFalse = () => {
    setIsExpanded(false);
  };

  const toggleExpansion = () => {
    if (!isExpanded) {
      fetchInitialData();
    } else {
      setIsExpanded(false);
    }
  };

  // const handleIconClick = () => {
  //   // Solo limpia el valor del input, sin alterar los valores seleccionados
  //   setInputValue('');
  //   onSelect({lable:'' , value: ''})
  //   setIsExpanded(true);

  //   // Mantiene el foco en el input después de limpiarlo
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // };
  const handleIconClick = () => {
    if (disabled) return;
    setInputValue(''); // Limpiar el valor de entrada
    onSelect({ label: '', value: '' }); // Opcional, dependiendo de tu lógica de selección
    if (inputRef.current) inputRef.current.focus(); // Mover el foco al input
    setIsExpanded(true);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadExcel && event.target.files) {
      uploadExcel(event.target.files[0], selectedValues).then((data: any) => {
        setSelectedValues(data);
        setValue(data.map((item: any) => item));
        if (fileInputRef.current) {
          fileInputRef.current.value = '';  // Restablecer el valor del input de archivo
        }
      });
    }
  };

  const calculatePosition = () => {
    if (isExpanded && inputRef.current && selectRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      const selectRect = selectRef.current.getBoundingClientRect();
      const gap = 4; // Espacio entre el input y el select
      const viewportHeight = window.innerHeight;

      const modal = inputRef.current.closest('#Modal');
      let top = inputRect.bottom + gap;
      let positionUpwards = false;

      if (top + selectRect.height > viewportHeight) {
        top = inputRect.top - selectRect.height - gap;
        positionUpwards = true;
      }
      // Calculamos la altura total de los chips seleccionados
      let chipsHeight = 0;
      if (multiple && inputRef.current) {
        const chipsContainer = inputRef.current.closest(`.${style.app_input_container}`);
        chipsHeight = chipsContainer ? chipsContainer.getBoundingClientRect().height - inputRect.height : 0;
      }
      if (modal) {
        const modalRect = modal.getBoundingClientRect();
        top = positionUpwards
          ? inputRect.top - selectRect.height - modalRect.top - gap
          : inputRect.bottom - modalRect.top + gap;
        const left = inputRect.left - modalRect.left;
        selectRef.current.style.position = 'fixed';
        selectRef.current.style.top = `${top}px`;
        selectRef.current.style.left = `${left}px`;
        selectRef.current.style.width = `${inputRect.width}px`;
      } else {
        top = positionUpwards
          ? inputRect.top - selectRect.height - inputRect.top - gap
          : inputRect.bottom - inputRect.top + gap + chipsHeight; // Sumar altura de los chips
        selectRef.current.style.position = 'absolute';
        selectRef.current.style.top = `${top}px`;
        selectRef.current.style.width = `${inputRect.width}px`;
      }
    }
  };

  useEffect(() => {
    const handleOutsideClickOrScroll = (event: Event) => {
      if (
        selectRef.current && !selectRef.current.contains(event.target as Node) &&
        inputRef.current && !inputRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false);
      }
    };

    if (isExpanded) {
      calculatePosition(); // Asegura que la posición se calcule al expandir
      document.addEventListener("mousedown", handleOutsideClickOrScroll);
      document.addEventListener("scroll", handleOutsideClickOrScroll, true); // Captura el evento de scroll
      window.addEventListener("resize", calculatePosition, true);
    } else {
      document.removeEventListener("mousedown", handleOutsideClickOrScroll);
      document.removeEventListener("scroll", handleOutsideClickOrScroll, true);
      window.removeEventListener("resize", calculatePosition, true);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickOrScroll);
      document.removeEventListener("scroll", handleOutsideClickOrScroll, true);
      window.removeEventListener("resize", calculatePosition, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded, selectedValues]);


  useEffect(() => {
    window.addEventListener('resize', calculatePosition);
    window.addEventListener('scroll', calculatePosition);

    return () => {
      window.removeEventListener('resize', calculatePosition);
      window.removeEventListener('scroll', calculatePosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isExpanded && <div className={style.overlay} onClick={() => changeIsExpandFalse()}></div>}
      <div className={style.container__input}>
        {name && (
          <label htmlFor={name} className={style.input__label}>
            {textLabel}
            {uploadExcel && (
              <>
                <span
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() => fileInputRef.current?.click()}
                >
                  {labelExcel}
                  <AppGetIcon name="upload" strokeColor="#5B5F62" />
                </span>
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  accept=".xlsx,.xls"
                />
              </>
            )}
          </label>
        )}
        <div className={`${style.app_input_container} ${multiple ? style.multiple : ''} ${disabled ? style.disabled : ''}`} style={{ border: ' 1px solid #8a8c8d', borderRadius: '8px' }}>
          {multiple && selectedValues.map((item, index) => (
            <div key={index} className={style.chip}>
              {item.label}
              <span onClick={() => handleRemoveChip(item.value)} className={style.chipClose}>&times;</span>
            </div>
          ))}
          <input
            ref={inputRef}
            type="text"
            autoComplete="off"
            className={classNameInput ? classNameInput : style.input__input}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={selectedValues.length > 0 ? 'Buscar...' : 'Seleccionar opción'}
            style={{ paddingRight: '10px', border: 'none' }}
            onClick={toggleExpansion}
            disabled={disabled}
          />

          <div
            onMouseEnter={() => setIsHoveredTrash(true)}
            onMouseLeave={() => setIsHoveredTrash(false)}
            style={{ position: 'absolute', right: '0px', bottom: '-10px', transform: 'translateY(-50%)', zIndex: 1 }}
          >
            <AppGetIcon
              name={inputValue?.length > 0 ? 'trash' : "arrow-down"}
              className={style.selectIcon}
              onClick={inputValue?.length > 0 ? handleIconClick : toggleExpansion}
              strokeColor="#5A5F65"
              hoverColor={inputValue?.length > 0 && isHoveredTrash ? "#D43C3C" : undefined}
            />
          </div>
          <div ref={selectRef} className={`${style.select} ${isExpanded ? style.expanded : ""}`}>
            {isExpanded && (
              isLoading ? (
                <div className={style.loading}>Cargando...</div>
              ) : (
                <div className={style.options}>
                  {showSelectAll && (
                    <div
                      className={`${style.option} ${style.select_all} ${selectedValues.some(selected => selected.value === SELECT_ALL_VALUE) ? style.selected : ''}`}
                      onClick={() => handleOptionClick({ label: "Seleccionar todos", value: SELECT_ALL_VALUE })}
                    >
                      Seleccionar todos
                    </div>
                  )}
                  {selectValue.length > 0 ? (
                    selectValue.map((item, index) => (
                      <div
                        key={index}
                        className={`${style.option} ${selectedValues.some(selected => selected.value === item.value) ? style.selected : ''}`}
                        onClick={() => handleOptionClick(item)}
                      >
                        {item.label}
                      </div>
                    ))
                  ) : (
                    <div style={{padding: '8px', textAlign:'center'}} className={style.no_options}>No se encontraron opciones</div>
                  )}
                 {selectValue.length === 0 && addString && (
  <div style={{ padding: '8px' }}>
    <div
      className={style.buttom_add}
      onMouseEnter={() => setisHoveredAdd(true)}
      onMouseLeave={() => setisHoveredAdd(false)}
      onClick={() => {
        if (inputValue.trim() !== '') {
          const newOption = { label: inputValue, value: inputValue }; // Generar un identificador único
          handleOptionClick(newOption, true); // Se pasa un flag para indicar que es un nuevo elemento
        }
      }}
    >
      <AppGetIcon 
        name="plus" 
        strokeColor="#042F53" 
        hoverColor={isHoveredAdd ? '#FFF' : undefined} 
      />
      {textAddString}
    </div>
  </div>
)}

                </div>
              )
            )}
          </div>

        </div>
        <div className={style.containerError}>
          <ErrorMessage
            className={style.messageError}
            name={name}
            component="div"
          />
        </div>
      </div>
    </>
  );
};

export default AppInputSearch;
