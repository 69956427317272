
import { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import useFormattedDate from "../../../../common/hooks/useFormattedDate";
import { addMonths, differenceInMonths } from "date-fns";

import DateInput from "../../../../common/UI/Inputs/InputDate";
import { IOption } from '../../../../common/UI/Nabvar/DropdownSelect';
import AppInputSearchFilter from "../../../../common/UI/Inputs/appInputSearchFilter";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";
import { useInitDataStore } from "../../../../store/initData.store";
import InputSearchFilter from "../../../../common/UI/Inputs/InputSearchFilter";


import style from "./AsistanceTeaching.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";

import { DataSelectDto } from "../../../../common/dtos/general.dto";
import { CreateOrUpdateGetRoomsMultipleService } from "../../../rotationScreen/services/DashboardScreenView/createOrUpdateGetRoomsMultiple.service";
import { GetTermCodeWithPaginationService } from "../../../rotationScreen/services/DashboardScreenView/getTermCodeWithPagination.service";
import { GetCampusWithPaginationService } from "../../../rotationScreen/services/getCampusWithPagination.service";
import { GetBuildingWithPaginationService } from "../../../rotationScreen/services/getBuildingWithPagination.service";
import { GetHoursBlockWithPaginationService } from "../../../rotationScreen/services/getHoursBlockWithPagination.service";
import ExcelButton from "../../../../common/UI/DataTable/excelButton";
import { GetReportAsistanceTeachingViewService } from "../../services/AssistanceTeaching/getReportAsistanceTeachingView.service";
import usePermission from "../../../../common/hooks/usePermission";
import { toast } from "react-toastify";
import AppGetIcon from "../../../../common/UI/AppGetIcon";

const createOrUpdateGetRoomsMultipleService = new CreateOrUpdateGetRoomsMultipleService();
const getReportAsistanceTeachingViewService = new GetReportAsistanceTeachingViewService();
export default function GeneralReservationLimitPage() {
  const hFormatDate = useFormattedDate();
  const canListDashboardScreen = usePermission('reports.assistance.teaching', 'list');

  const today = new Date();
  const dataInitDataStore = useInitDataStore(
    (state) => ({
      userRoles: state.initData.userRoles.map(item => ({ value: String(item.roleId), label: item.role.name })),
      rolSelected: state.rolSelected,
      setRolSelected: state.setRolSelected,
      setItemRolePermissionId: state.setItemRolePermissionId,
      userCampus: state.initData?.userCampus ?? [].map((item: IOption) => ({
        campusId: String(item.facultyCode ?? ''),
        campusName: String(item.facultyName ?? '')
      })),
    }),
    shallow
  );
  const [buildingIds, setBuildingIds] = useState<any>('');
  const [campusIds, setCampusIds] = useState<any>('');
  const [roomCodes, setRoomCodes] = useState<any>('');
  const [termCodes, setTermCodes] = useState<any>('');
  const [startDate, setStartDate] = useState<any>(today);
  const [endDate, setEndate] = useState<any>(today);

  const [campusFilter, setCampusFilter] = useState<DataSelectDto>({
    label: String(dataInitDataStore?.userCampus[0]?.campusName ?? ''),
    value: String(dataInitDataStore?.userCampus[0]?.campusId ?? '')
  });

  const [buildingIdFilter, setBuildingIdFilter] = useState<DataSelectDto>({
    label: "",
    value: "",
  });

  const typeResourceData: DataSelectDto[] = [
    {
      label: "Todos",
      value: "",
    },
    {
      label: "Recintos Academicos",
      value: "1",
    },
    {
      label: "Extracurricular",
      value: "2",
    },
  ];

  const [typeResourceId, setTypeResourceId] = useState<DataSelectDto>({
    label: "Todos",
    value: "",
  });
  const [hoursBlockStart, setHoursBlockStart] = useState({ label: '', value: '' });
  const [hoursBlockEnd, setHoursBlockEnd] = useState({ label: '', value: '' });
  const [searchNrc, setSearchNrc] = useState<string>('');
  const [searchRoom, setSearchRoom] = useState({
    label: '',
    value: ''
  });
  const [termcCodeIdVal, setTermCodeIdVal] = useState<DataSelectDto>({
    label: '',
    value: ''
  })

  const serviceCallTermCode = useCallback(async (value: string) => {
    const response: any = await GetTermCodeWithPaginationService({
      search: value,
      perPage: 1000
    });
    return response.data.map((item: any) => ({ label: item.SYBTERM_TERM_DESC, value: item.sybterm_term_code }));
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  const serviceCallCampus = useCallback(async (value: string) => {
    const response: any = await GetCampusWithPaginationService({
      search: value,
      perPage: 1000
    });
    return response.data.map((item: any) => ({
      label: item.campusId,
      value: item.campusId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);


  const serviceCallBuilding = useCallback(
    async (dataSearch: any) => {
      let campusSelected = campusIds.value;
      const response: any = await GetBuildingWithPaginationService({
        search: dataSearch,
        campusId: campusSelected,
        perPage: 1000,
        sort: 'id',
        order: 'DESC'
      });
      return response.data.map((item: any) => ({
        label: item.buildingId,
        value: item.buildingId,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [campusIds.value]
  );
  const serviceCallRoomsFilter = useCallback(
    async (dataSearch: any) => {
      let buildingIdSelected = buildingIds.value;
      let buildingIdsArray = buildingIdSelected.split(',');

      let dataCreated: any = {
        search: dataSearch,
        buildingIds: buildingIdsArray,
      };

      try {
        const response = await createOrUpdateGetRoomsMultipleService.run(dataCreated);
        return response.data.map((item: any) => ({
          label: item.roomCode,
          value: item.roomCode,
        }));
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message ;
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="xCircleFill" />
          {errorMessage?? 'Ocurrio un error, contactese con el administrador'}
        </div>,
        { icon: false }
      );
        console.error("Error guardando el recurso", error);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [buildingIds.value,]
  );

  const serviceCallHoursBlock = async (dataSearch: any) => {
    const response: any = await GetHoursBlockWithPaginationService(dataSearch);
    const filteredData = response.data
      .filter((item: any) => item.type === 0)
      .map((item: any) => ({
        ...item,
        label: item.hourBlock,
        value: item.id
      }));
    return filteredData;
  };

	const serviceCallHoursBlockEnd = async (dataSearch: any) => {
		const response: any = await GetHoursBlockWithPaginationService(dataSearch);
		const hourStart = hoursBlockStart;
		const filteredData = response.data.filter((item: any) => {
				const itemHour = item.hourBlock;
				const isTypeOne = item.type === 1;
				const isHourValid = itemHour > hourStart?.label;
				return isTypeOne && isHourValid;
			}).map((item: any) => {
				return {
					...item,
					label: item.hourBlock,
					value: item.id,
				};
			});
		return filteredData;
	};


  useEffect(() => {
    updateGlobalState(canListDashboardScreen[1])
    const data = Array.isArray(buildingIdFilter) ? buildingIdFilter : [];
    const dataCampus = Array.isArray(campusFilter) ? campusFilter : [];
    const dataRoomCode = Array.isArray(searchRoom) ? searchRoom : [];
    const dataTermCode = Array.isArray(termcCodeIdVal) ? termcCodeIdVal : [];

    let databuildingIds = data.length ? data.map((item) => item.value).join(",") : "";
    let searchCampus = dataCampus.length ? dataCampus.map((item) => item.value).join(",") : "";
    let searchRooms = dataRoomCode.length ? dataRoomCode.map((item) => item.value).join(",") : "";
    let termCodes = dataTermCode.length ? dataTermCode.map((item) => item.value).join(",") : "";

    setBuildingIds({ label: databuildingIds, value: databuildingIds });
    setCampusIds({ label: searchCampus, value: searchCampus });
    setRoomCodes({ label: searchRooms, value: searchRooms });
    setTermCodes({ label: termCodes, value: searchRooms });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, campusFilter.value, buildingIdFilter, searchRoom, termcCodeIdVal.value]);
  const updateGlobalState = (itemRolePermissionId: number) => {
    dataInitDataStore.setItemRolePermissionId(itemRolePermissionId);
	};

  return (
    <div className={style.containerQr}>
      <h2 className={style.titlePage}>Selecciona el filtro personalizado para descargar el reporte</h2>
      <div className={style.mainContent}>

        <div className={style.inputContainer}>

          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 8px" }}
          >
            <DateInput
              name="fecha"
              textLabel="Seleccionar rango"
              classNameInput={styleForm.input__input}
              initialValue={[startDate, endDate]}
              range={true}
              dateFormat="dd-MM-yyyy"
              onChange={(dateSelected: any) => {
                // Actualiza las fechas seleccionadas
                if (dateSelected[0]) setStartDate(dateSelected[0]);
                if (dateSelected[1]) setEndate(dateSelected[1]);

                // Valida el rango hacia adelante y hacia atrás
                if (
                  dateSelected[0] &&
                  dateSelected[1] &&
                  (differenceInMonths(dateSelected[1], dateSelected[0]) > 6 ||
                    differenceInMonths(dateSelected[0], dateSelected[1]) > 6)
                ) {
                  setEndate(addMonths(dateSelected[0], 6)); // Ajusta automáticamente la fecha final
                }
              }}
              minDate={startDate ? addMonths(startDate, -6) : addMonths(new Date(), -6)} // Permite 6 meses hacia atrás
              maxDate={startDate ? addMonths(startDate, 6) : addMonths(new Date(), 6)}  // Permite 6 meses hacia adelante
              clearable={false}
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="type"
              classNameInput={styleForm.input__input}
              textLabel="Tipo recurso"
              data={typeResourceData}
              onSelect={(selected) => {
                setTypeResourceId({
                  label: selected.label,
                  value: selected.value
                });
              }}
              selected={typeResourceId}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 1px" }}
          >
            <InputSearch
              key={Math.random()}
              serviceCall={serviceCallHoursBlock}
              onSelect={selected =>
                setHoursBlockStart({
                  label: selected.label,
                  value: selected.value
                })
              }
              selected={hoursBlockStart}
              name="startHoursBlock"
              textLabel="Bloque inicio"
              classNameInput={styleForm.input__input}
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", }}
          >

            <InputSearch
              key={Math.random()}
              serviceCall={serviceCallHoursBlockEnd}
              onSelect={setHoursBlockEnd}
              selected={hoursBlockEnd}
              name="endHoursBlock"
              textLabel="Bloque fin"
              classNameInput={styleForm.input__input}
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px", paddingBottom: " 10px" }}
          >

            <InputSearchFilter
              name="searchNrc"
              placeholder="Buscar por NRC"
              label="Buscar por NRC"
              value={searchNrc}  // Pasa el valor del estado como prop
              onSearchChange={(value) => setSearchNrc(value)}  // Mantiene la funcionalidad del callback
              classNameInput={styleForm.input__input}
            />

          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "100%" }}
          >
            <AppInputSearchFilter
              key={Math.random()}
              name="campusId"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona sedes/campus"
              serviceCall={serviceCallCampus}
              onSelect={(selected) => {
                setCampusFilter(selected);
                setBuildingIdFilter({
                  label: '',
                  value: ''
                });
                setSearchRoom({
                  label: '',
                  value: ''
                });
              }}
              selected={campusFilter}
              multiple={true}
              showSelectAll={true}

            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "100%" }}
          >
            <AppInputSearchFilter
              key={Math.random()}
              name="buildingId"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona edificios*"
              serviceCall={serviceCallBuilding}
              onSelect={(selected) => {
                setBuildingIdFilter(selected);
                setSearchRoom({
                  label: '',
                  value: ''
                });
              }}
              selected={buildingIdFilter}
              multiple={true}
              showSelectAll={true}

            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "100%", paddingBottom: " 6px" }}
          >
            <AppInputSearchFilter
              key={Math.random()}
              name="roomsId"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona salones"
              serviceCall={serviceCallRoomsFilter}
              onSelect={(selected) => {
                setSearchRoom(selected);
              }}
              selected={searchRoom}
              multiple={true}
              showSelectAll={true}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "100%", }}
          >
            <AppInputSearchFilter
              key={Math.random()}
              name="termCode"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona Período"
              serviceCall={serviceCallTermCode}
              onSelect={(selected) => {
                setTermCodeIdVal(selected);
              }}
              selected={termcCodeIdVal}
              multiple={true}
              showSelectAll={true}
            />

          </div>
        </div>

      </div>
      <div className={style.fixedButton}>
        <div style={{ display: 'flex' }}>
        {canListDashboardScreen ? (
  <ExcelButton
    service={getReportAsistanceTeachingViewService}
    params={{
      startDate: startDate ? hFormatDate(startDate, "yyyy-MM-dd") : "",
      endDate: endDate ? hFormatDate(endDate, "yyyy-MM-dd") : "",
      campusId: campusIds.value ?? '',
      buildingId: buildingIds.value ?? '',
      roomCode: roomCodes.value ?? '',
      type: typeResourceId.value ? Number(typeResourceId.value) : null,
      startTime: hoursBlockStart.label,
      endTime: hoursBlockEnd.label,
      NRC: searchNrc,
      termCode: termCodes.label,
    }}
    nameButton="Exportar reporte"
    enabled={true}
    nameFile="Reporte Asistencia Docente"
  />
) : undefined}

        </div>

      </div>
    </div>

  );
}
