
import { useEffect, useState } from "react";
import { useInitDataStore } from "../../../../store/initData.store";

import { shallow } from "zustand/shallow";
import AppItemsWithPagination from "../../../../common/UI/AppItemsWithPagination";
import CardEnabledCampus from "../../components/cardEnabledCampus";

import style from "./EnabledCampusStudent.module.css";

import { GetEnabledCampuSWithPaginationService } from "../../services/EnabledCampus/getEnabledCampuSWithPagination.service";
import usePermission from "../../../../common/hooks/usePermission";

const getEnabledCampuSWithPaginationService = new GetEnabledCampuSWithPaginationService();

export default function EnabledCampusStudentPage() {
  const canEditLimitEnabledCampus = usePermission('administration.booking.campus.student', 'edit');
  const canListLimitEnabledCampus = usePermission('administration.booking.campus.student', 'list');
  const dataInitDataStore = useInitDataStore(
    (state) => ({
      userRoles: state.initData.userRoles.map(item => ({ value: String(item.roleId), label: item.role.name })),
      rolSelected: state.rolSelected,
      setRolSelected: state.setRolSelected,
      setItemRolePermissionId: state.setItemRolePermissionId,
      userCampus: state.initData?.userCampus ?? [].map((item: any) => ({
        campusId: String(item.campusId),
        campusName: String(item.campusName)
      })),
    }),
    shallow
  );
  const [isReloadData, setIsReloadData] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);


  useEffect(() => {
    updateGlobalState(canListLimitEnabledCampus[0]);
    setIsReloadData(!isReloadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateGlobalState = (itemRolePermissionId: number) => {
    dataInitDataStore.setItemRolePermissionId(itemRolePermissionId);
	};
  return (
    <div className={style.containerQr}>
      <AppItemsWithPagination
        title="Administración Campus Estudiantes"
        service={getEnabledCampuSWithPaginationService}
        refreshKey={refreshKey}
      >
        {(itemData) => (
          <div style={{ height: '100%' }} className={style.container__componente_body_Card} key={itemData.campusId}>
            <CardEnabledCampus
      key={itemData.campusId}
      campusId={String(itemData.campusId)}
      enabled={itemData.enabled}
      title={itemData.campusName}
      titlePosition="start"
      backgroundColor="#042F53"
      titleColor="#FFF"
      showCheckbox={
        canEditLimitEnabledCampus ? () => {
              return true;
            }
          : undefined
      }
      
      
      refreshKey={setRefreshKey}
    />
          </div>
        )}
      </AppItemsWithPagination>
    </div>
  );
}
