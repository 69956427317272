
import { useCallback, useEffect, useState } from "react";
import { useInitDataStore } from "../../../../store/initData.store";

import useFormattedDate from "../../../../common/hooks/useFormattedDate";
import { shallow } from "zustand/shallow";
import DateInput from "../../../../common/UI/Inputs/InputDate";
import ConatinerFilter from "../../../../common/UI/DataTable/filterModal/containerFilter";
import DataTable from "../../../../common/UI/DataTable/dataTable";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";

import style from "./EmailsLogs.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";


import { DataSelectDto } from "../../../../common/dtos/general.dto";
import usePermission from "../../../../common/hooks/usePermission";
import { GetEmailsLogsWithPaginationService } from "../../services/EmailsLogs/getEmailsLogsWithPagination.service";
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import { GetLogsEntitiesWithPaginationService } from "../../services/getLogsEntitiesWithPagination.service";






const getEmailsLogsWithPaginationService = new GetEmailsLogsWithPaginationService();

export default function CampusReservationLimitPage() {
  const hFormatDate = useFormattedDate();
  const canListEmailLogs = usePermission('historical.emails.logs', 'list');

  const dataInitDataStore = useInitDataStore(
    (state) => ({
      userRoles: state.initData.userRoles.map(item => ({ value: String(item.roleId), label: item.role.name })),
      rolSelected: state.rolSelected,
      setItemRolePermissionId: state.setItemRolePermissionId,
      setRolSelected: state.setRolSelected,
      userCampus: state.initData?.userCampus ?? [].map((item: any) => ({
        campusId: String(item.campusId),
        campusName: String(item.campusName)
      })),
    }),
    shallow
  );
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [isReloadData, setIsReloadData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [startDate, setStartDate] = useState<any>(startOfMonth);
  const [endDate, setEndate] = useState<any>(endOfMonth);
  const [specificActionFilter, setSpecificActionFilter] = useState<DataSelectDto>({label: "", value: "",});
  // const [entityFilter, setEntityFilter] = useState<DataSelectDto>({label: "", value: "",});
  const [warningMessage, setWarningMessage] = useState("");

const serviceCallLogsEntites = useCallback(async (value: string) => {
    try {
      const response: any = await GetLogsEntitiesWithPaginationService({
				type: 2,
        noPage: true,
        search: value,
        perPage: 100
      });
      
        return response.data.map((item: any) => ({
          label: item.label,
          value: item.value,
        }));
      

    } catch (error) {
      console.error("Error fetching campus:", error);
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificActionFilter.value]);
  
  const columns = [
    {
      header: "NOMBRE",
      field: "fullname",
    },
    {
      header: "CORREO",
      field: "userEmail",
    },
    {
      header: "ENTIDAD",
      field: "entity",
    },
    {
      header: "ACCIÓN",
      field: "action",
    },
    {
      header: "DESCRIPCIÓN ACCIÓN",
      field: "specificAction",
    },
    {
      header: "ERROR",
      field: "errorData",
    },
    {
      header: "ANTES",
      field: "dataBefore",
    },
    {
      header: "DESPUES",
      field: "dataAfter",
    },
  ];


  const handleFilterButtonClick = () => {
    setShowFilterModal(true);
  };
  const handleFilterModalClose = () => {
    setShowFilterModal(false);
  };
  const [filterKey, setFilterKey] = useState(0); // Estado para forzar la actualización de DateInput
  const handleDeleteFilter = () => {
    setStartDate(null);
    setEndate(null);
    setSpecificActionFilter({label:"", value: ""});
    // setEntityFilter({label:"", value: ""});
    setFilterKey((prevKey) => prevKey + 1); // Forzar la actualización de DateInput
  };
  // Lógica de validación
  const validateFilter = () => {
    if (!startDate && !endDate) {
      setWarningMessage("El rango de fechas es obligatorio.");
      return false;
    }
    setWarningMessage(""); 
    return true;
  };
  // Manejador del filtro
  const handleFilter = () => {
    if (validateFilter()) {
      setIsReloadData(!isReloadData); 
    }
  };
  useEffect(() => {
    updateGlobalState(canListEmailLogs[1])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateGlobalState = (itemRolePermissionId: number) => {
    dataInitDataStore.setItemRolePermissionId(itemRolePermissionId);
	};
  return (
    <div className={style.containerQr}>
      <DataTable
        title="Reporte Logs Correos"
        columns={columns}
        service={getEmailsLogsWithPaginationService}
        params={{
          startDate: startDate ? hFormatDate(startDate, "yyyy-MM-dd") : "",
          endDate: endDate ? hFormatDate(endDate, "yyyy-MM-dd") : "",
          specificAction: specificActionFilter.value,
          // entity: entityFilter.value,
        }}
        searchText="Buscar Correo o Responsable"
        reloadData={isReloadData}
        excelButton={{
          enabled: true,
          nameFile: `Reporte Logs Correos`,
          getExcelData: (data) => {
            return data.map((row) => ({
              NOMBRE: row.fullname,
              CORREO: row.email,
              ENTIDAD: row.entity,
              ACCIÓN: row.action,
              'DESCRIPCIÓN ACCIÓN': row.specificAction,
              ERROR: row.errorData,
              ANTES: row.dataBefore,
              DESPUES: row.dataAfter,
            }));
          },
        }}
      >
        <ConatinerFilter
          nameIcon="filter"
          onClick={() => handleFilterButtonClick()}
          isOpen={showFilterModal}
          onClose={handleFilterModalClose}
          handleDeleteFilter={handleDeleteFilter}
          handleFilter={handleFilter} // Usa la nueva función de manejo de filtro
        >
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <DateInput
              key={filterKey} // Utiliza la clave para forzar la actualización del componente
              name="fecha"
              textLabel="Selecionar rango"
              classNameInput={styleForm.input__input}
              initialValue={[startDate, endDate]}
              range={true}
              dateFormat="dd-MM-yyyy"
              onChange={(dateSelected: any) => {
                setStartDate(dateSelected[0]);
                setEndate(dateSelected[1]);
              }}
            />
            <div></div>
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="specificAction"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona Acción Específica"
              serviceCall={serviceCallLogsEntites}
              onSelect={(selected) => {
                setSpecificActionFilter(selected);
              }}
              selected={specificActionFilter}
            />
          </div>
          {/* <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="entity"
              classNameInput={styleForm.input__input}
              textLabel="Selecciona Entidad"
              data={getEntityOptions()}
              onSelect={(selected) => {
                setEntityFilter(selected);
              }}
              selected={entityFilter}
            />
          </div> */}
          {warningMessage && (

<div
  style={{
    backgroundColor: '#FFF3CD', // Fondo de advertencia amarillo claro
    color: '#856404', // Color del texto de advertencia (marrón)
    border: '1px solid #ffeeba', // Borde de advertencia
    padding: '10px', // Espacio interno
    borderRadius: '5px', // Bordes redondeados
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px', // Separación superior
    width: '100%'
  }}
>
  <AppGetIcon name="alert" fillColor="#856404" />
  <span style={{ fontWeight: 'bold' }}>
    {warningMessage}
  </span>
</div>



)}
        </ConatinerFilter>
      </DataTable>
    </div>
  );
}
