import { useCallback, useEffect, useState } from "react";
import { Field, FieldProps, Form, Formik } from "formik";
import * as yup from "yup";

import { InputText } from "../../../../common/UI/Inputs";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import Loading from "../../../../common/UI/Loading";
import ProgressBar from "../../../../common/UI/ProgressBar";

import { DataSelectDto } from "../../../../common/dtos/general.dto";

import style from "./Resources.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";
import styleDataTable from "../../../../common/UI/DataTable/dataTable.module.css";

import { GetCampusWithPaginationService } from "../../services/getCampusWithPagination.service";
import { GetRoomsService } from "../../services/getRooms.service";
import { GetBuildingWithPaginationService } from "../../services/getBuildingWithPagination.service";
import { GetResourceWithPaginationService } from "../../services/Resources/getResourceWithPagination.service";
import { CreateOrUpdateResourceService, IResource } from "../../services/Resources/createOrUpdateResource.service";
import usePermission from "../../../../common/hooks/usePermission";
import { useInitDataStore } from "../../../../store/initData.store";
import { shallow } from "zustand/shallow";
import { toast } from "react-toastify";

const createOrUpdateResourceService = new CreateOrUpdateResourceService();

export default function ResourcesPage() {
  const canCreateResource = usePermission('booking.resources', 'create');
  const canEditResource = usePermission('booking.resources', 'edit');
  const canListResource = usePermission('booking.resources', 'list');
  
    const dataInitDataStore = useInitDataStore(
      (state) => ({
        setRolSelected: state.setRolSelected,
        setItemRolePermissionId: state.setItemRolePermissionId,
      }),
      shallow
    );
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [campus, setCampus] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const [buildingId, setBuildingId] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const [roomId, setRoomId] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const typeResourceData: DataSelectDto[] = [
    {
      label: "Recintos Academicos",
      value: "curricular",
    },
    {
      label: "Extracurricular",
      value: "extracurricular",
    },
  ];
  const [typeResourceId, setTypeResourceId] = useState<DataSelectDto>({
    label: "",
    value: "",
  });
  const [statusFilter, setStatusFilter] = useState<DataSelectDto>({
    label: "",
    value: "",
  });


  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [editForm, setEditForm] = useState<boolean>(false);
  const [createForm, setCreateForm] = useState<boolean>(false);
  const [resourceData, setResourcesData] = useState<any[]>([]);
  const [initialData, setInitialData] = useState<IResource | null>(null);
  const [isHoveredEdit, setIsHoveredEdit] = useState<boolean>(false);
  const [isHoveredCreate, setIsHoveredCreate] = useState<boolean>(false);
  const [campusSelectCreate, setCampusSelectCreate] = useState<DataSelectDto>({
    label: "",
    value: "",
  });

  const serviceCallCampus = useCallback(async (value: string) => {
    const response: any = await GetCampusWithPaginationService({
      search: value,
      perPage: 100
    });
    return response.data.map((item: any) => ({
      label: item.campusId,
      value: item.campusId,
    }));
  }, []);

  const serviceCallBuilding = useCallback(
    async (dataSearch: any) => {
      let campusSelected = campus.value || campusSelectCreate.value;
      const response: any = await GetBuildingWithPaginationService({
        search: dataSearch,
        campusId: campusSelected,
        perPage: 100,
        sort: 'id',
        order: 'DESC'
      });
      return response.data.map((item: any) => ({
        label: item.buildingId,
        value: item.buildingId,
      }));
    },
    [campus.value, campusSelectCreate.value]
  );

  const serviceCallRooms = useCallback(async (dataSearch: any) => {
    let campusSelected = campus.value || campusSelectCreate.value;
    let buildingIdSelected = buildingId.value;
    let response: any = [];
    if (typeResourceId.value === 'curricular') {
      response = await GetRoomsService({
        search: dataSearch,
        campusId: campusSelected,
        buildingId: buildingIdSelected,
        perPage: 100,
        sort: 'id',
        order: 'DESC'
      });
    } else {
      response = await GetResourceWithPaginationService({
        search: dataSearch,
        type: typeResourceId.value,
        campusId: campusSelected,
        buildingId: buildingIdSelected,
        perPage: 1000,
        sort: 'id',
        order: 'DESC'
      });
    };
    return response.data.map((item: any) => ({
      label: item.roomCode,
      value: item.roomCode,
    }));
  },
    [campus.value, campusSelectCreate.value, buildingId.value, typeResourceId.value]
  );

  const handleGetResources = async () => {
    try {
      if (typeResourceId.value) {
        setLoadingSearch(true);
        const requestParams: any = {
          perPage: 10000,
          campusId: campus.value,
          buildingId: buildingId.value,
          roomCode: roomId.value,
          type: typeResourceId.value,
          sort: 'id',
          order: 'DESC'
        };
        // Solo incluir el estado si se ha seleccionado un filtro de estado
        if (statusFilter?.value) {
          requestParams.status = statusFilter.value;
        }
        const response: any = await GetResourceWithPaginationService(requestParams);
        setResourcesData(
          response.data.map((item: any) => ({
            ...item,
            type: typeResourceId.label,
          }))
        );
        setLoadingSearch(false);
      } else {
        setResourcesData([]);
      }
    } catch (error) {
      console.log("Error al ejecutar el servicio de recursos");
      setLoadingSearch(false);
    }
  };

  const getStatusInfo = (status: number, type: string) => {
    if (type === "Recintos Academicos") {
      switch (status) {
        case 0:
          return { color: "#15CB20", text: "Activo" };
        case 1:
          return { color: "#15CB20", text: "Activo" };
        case 2:
          return { color: "#15CB20", text: "Activo" };
        case 3:
          return { color: "#962330", text: "Inactivo" };
        default:
          return { color: "", text: "" };
      }
    } else if (type === "Extracurricular") {
      switch (status) {
        case 1:
          return { color: "#15CB20", text: "Activo" };
        case 0:
          return { color: "#962330", text: "Inactivo" };
        default:
          return { color: "", text: "" };
      }
    } else {
      return { color: "", text: "" };
    };
  };

  const resourceSchema = yup.object().shape({
    id: yup.mixed().nullable(),
    roomId: yup.string(),
    roomCode: yup.string().required("El campo código es obligatorio"),
    roomDescription: yup.string().required("El campo descripción es obligatorio"),
    buildingId: yup.string().required("El campo edificio es obligatorio"),
    buildingDescription: yup.string().required("El campo edificio es obligatorio"),
    campusId: yup.string().required("El campo campus es obligatorio"),
    campusDescription: yup.string().required("El campo campus es obligatorio"),
    capacity: yup.number().required("El campo capacidad es obligatorio").positive("La capacidad debe ser un número positivo"),
    // nm_high: yup.number().positive("La altura debe ser un número positivo"),
    // nm_width: yup.number().positive("El ancho debe ser un número positivo"),
    status: yup.number(),
  });

  const handleSubmitResource = async (values: IResource, actions: any) => {
    if (actions.errors && Object.keys(actions.errors).length !== 0) {
      actions.setSubmitting(false);
      return;
    };
    if (values.nm_high < 0) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="xCircleFill" />
          {'El campo alto no puede ser negativo'}
        </div>,
        { icon: false }
      );
      return;
    }
    if (values.nm_width < 0) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="xCircleFill" />
          {'El campo alto no puede ser negativo'}
        </div>,
        { icon: false }
      );
      return;
    }
    setLoadingForm(true);
    try {
      const requestParams: any = {
        ...values,
        updatedAt: null
      };
      await updateGlobalState(values.id? canEditResource[1] : canCreateResource[1] );
      await createOrUpdateResourceService.run(requestParams);
      await updateGlobalState(canListResource[1]);
      setEditForm(false);
      setLoadingForm(false);
      // Volver a la vista anterior
      setCreateForm(false);
      setInitialData(null);

      // Refresca la lista de recursos después de guardar
      await handleGetResources();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message ;
    toast.error(
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <AppGetIcon name="xCircleFill" />
        {errorMessage?? 'Ocurrio un error, contactese con el administrador'}
      </div>,
      { icon: false }
    );
      console.error("Error guardando el recurso", error);
      setLoadingForm(false);
    }
  };

  const handleSelectedResource = (dataSelected: IResource) => {
    setInitialData({
      id: dataSelected.id,
      roomId: dataSelected.roomId,
      roomCode: dataSelected.roomCode,
      roomDescription: dataSelected.roomDescription,
      buildingId: dataSelected.buildingId,
      buildingDescription: dataSelected.buildingDescription,
      campusId: dataSelected.campusId,
      campusDescription: dataSelected.campusDescription,
      capacity: dataSelected.capacity,
      floorId: dataSelected.floorId,
      floorCode: dataSelected.floorCode,
      floorDescription: dataSelected.floorDescription,
      nm_high: dataSelected.nm_high,
      nm_long: dataSelected.nm_long,
      nm_width: dataSelected.nm_width,
      createdAt: dataSelected?.createdAt ?? null,
      updatedAt: dataSelected?.updatedAt ?? null,
      status: dataSelected.status,
    });
    setCreateForm(true);
  };
  useEffect(() => {
    updateGlobalState(canListResource[1]);
    if (!typeResourceId.value) {
      setInitialData(null);
      setCreateForm(false);
      setEditForm(false);
    }
    if (!campus.value) {
      setInitialData(null);
      setCreateForm(false);
      setEditForm(false);
    }
    if (!buildingId.value) {
      setInitialData(null);
      setCreateForm(false);
      setEditForm(false);
    }
    if (!roomId.value) {
      setInitialData(null);
      setCreateForm(false);
      setEditForm(false);
    }
    if (!statusFilter.value) {
      setInitialData(null);
      setCreateForm(false);
      setEditForm(false);
    }
    handleGetResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campus.value, buildingId.value, roomId.value, typeResourceId.value, statusFilter.value]);


  const getStatusOptions = () => {
    return [
      { label: "Activo", value: "1" },
      { label: "Inactivo", value: "0" },
    ];
  };
  const updateGlobalState = (itemRolePermissionId: any) => {
    dataInitDataStore.setItemRolePermissionId(itemRolePermissionId);
	};
  return (
    <div className={style.Resources__page}>
      <div className={style.Page__card_filters}>
        <div className={style.Card__filters_head}>
          <InputSearch
            key={Math.random()}
            name="type"
            classNameInput={styleForm.input__input}
            textLabel="Tipo recurso"
            data={typeResourceData}
            onSelect={(selected) => {
              setTypeResourceId(selected);
              setCampus({ label: '', value: '' });
              setBuildingId({ label: '', value: '' });
              setRoomId({ label: '', value: '' });
              setStatusFilter({ label: '', value: '' });
            }}
            selected={typeResourceId}
          />
          <InputSearch
            key={Math.random()}
            name="sede"
            classNameInput={styleForm.input__input}
            textLabel="Selecciona sede/campus"
            serviceCall={serviceCallCampus}
            onSelect={(selected) => {
              setCampus(selected);
              setBuildingId({ label: '', value: '' });
              setRoomId({ label: '', value: '' });
              setStatusFilter({ label: '', value: '' });
            }}
            selected={campus}
          />
          <InputSearch
            key={Math.random()}
            name="building"
            classNameInput={styleForm.input__input}
            textLabel="Selecciona edificio"
            serviceCall={serviceCallBuilding}
            onSelect={(selected) => {
              setBuildingId(selected);
              setRoomId({ label: '', value: '' });
              setStatusFilter({ label: '', value: '' });
            }}
            selected={buildingId}
          />
          <InputSearch
            key={Math.random()}
            name="rooms"
            classNameInput={styleForm.input__input}
            textLabel="Selecciona salón"
            serviceCall={serviceCallRooms}
            onSelect={(selected) => {
              setRoomId(selected);
              setStatusFilter({ label: '', value: '' });
            }}
            selected={roomId}
          />

          <InputSearch
            key={Math.random()}
            name="status"
            classNameInput={styleForm.input__input}
            textLabel="Selecciona estado"
            data={getStatusOptions()}
            onSelect={(selected) => {
              setStatusFilter(selected);
            }}
            selected={statusFilter}
          />
        </div>
        <div className={style.Card__filters_body}>
          {loadingSearch && <Loading />}
          {typeResourceId.value ? (
            resourceData.length > 0 ? (
              resourceData.map((item: any) => (
                <div
                  key={item.id}
                  className={`${style.Card__resources} ${initialData?.id === item.id
                    ? style.Card__resources_selected
                    : ""
                    }`}
                  onClick={() => handleSelectedResource(item)}
                >
                  <div className={style.Card__resources_container}>
                    <div
                      className={`${style.Card__resources_title} ${style.Card__title_start}`}
                    >
                      Salón
                    </div>
                    <div>{item.roomDescription}</div>
                  </div>
                  <div className={style.Card__resources_container}>
                    <div className={style.Card__resources_title}>Campus</div>
                    <div className={style.Card__resources_value}>
                      {item.campusDescription}
                    </div>
                  </div>
                  <div className={style.Card__resources_container}>
                    <div className={style.Card__resources_title}>Edificio</div>
                    <div className={style.Card__resources_value}>
                      {item.buildingId}
                    </div>
                  </div>
                  <div className={style.Card__resources_container}>
                    <div className={style.Card__resources_title}>
                      Tipo recurso
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textAlign: "center",
                      }}
                      className={style.Card__resources_value}
                    >
                      {item.type}
                      <div
                        className={style.Card__resources_status}
                        style={{
                          background: getStatusInfo(item.status, item.type)
                            .color,
                        }}
                      >
                        {getStatusInfo(item.status, item.type).text}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={styleDataTable.Container__notFound}>
                <AppGetIcon name="infoCircle" />
                <span style={{ width: "100%" }}>
                  No se encontraron recursos que coincidan con tu búsqueda.
                </span>
              </div>
            )
          ) : (
            <div className={styleDataTable.Container__notFound}>
              <AppGetIcon name="infoCircle" />
              <span style={{ width: "100%" }}>
                Por favor, selecciona un filtro para comenzar la búsqueda.
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={style.Page__card_view}>
        <div className={style.Card__view_header}>
          <div>
            {initialData?.id && typeResourceId.value === "extracurricular"
              ? `Editar recurso - ${initialData?.roomDescription}`
              : typeResourceId.value === "extracurricular"
                ? "Crear nuevo recurso extracurricular"
                : typeResourceId.value === "curricular"
                  ? "Información general"
                  : ""}
          </div>
          <div>
            {typeResourceId.value === "extracurricular" && (
              <div style={{ display: "flex", gap: "5px" }}>
                {
                  (initialData && canEditResource) &&
                  
                  <div
                    className={styleDataTable.buttom_option}
                    onClick={() => {
                      setEditForm(true);
                    }}
                    onMouseEnter={() => setIsHoveredEdit(true)}
                    onMouseLeave={() => setIsHoveredEdit(false)}
                  >
                    <AppGetIcon
                      name="edit"
                      classIcon={styleDataTable.sizeIcon}
                      hoverColor={isHoveredEdit ? '#FFF' : undefined}
                    />
                    Editar recurso  {isHoveredEdit}
                  </div>
                }
                {
                  (initialData && canCreateResource) &&
                <div
                  className={styleDataTable.buttom_option}
                  onClick={() => {
                    setCreateForm(true);
                    setEditForm(true);
                    setInitialData(null);
                  }}
                  onMouseEnter={() => setIsHoveredCreate(true)}
                  onMouseLeave={() => setIsHoveredCreate(false)}
                >
                  <AppGetIcon
                    name="plus"
                    classIcon={styleDataTable.sizeIcon}
                    hoverColor={isHoveredCreate ? "#FFF" : undefined}
                  />
                  Crear recurso
                </div>
                }
              </div>
            )}
          </div>
        </div>
        {loadingForm && <ProgressBar />}
        <div className={style.Card__view_body}>
          {!createForm && !typeResourceId.value ? (
            <div className={style.Container__info}>
              <div className={style.Container__infoMessage}>
                <AppGetIcon name="infoCircle" classIcon={style.iconInfo} /> No
                se encontraron resultados
              </div>
              <div className={style.Container__create}>
                <p>No se encontraron recursos que coincidan con tu búsqueda.</p>
              </div>
            </div>
          ) : !createForm ? (
            <div className={style.Container__info}>
              <div className={style.Container__infoMessage}>
                <AppGetIcon name="infoCircle" classIcon={style.iconInfo} /> No
                se encontraron resultados
              </div>
              <div className={style.Container__create}>
                <p>
                  Debes de seleccionar el salón o crear un nuevo recurso
                  extracurricular.
                </p>
              </div>
            </div>
          ) : (
            <Formik
              key={initialData?.id ?? "new"}
              initialValues={{
                id: initialData?.id ?? null,
                roomId: initialData?.roomId ?? "",
                roomCode: initialData?.roomCode ?? "",
                roomDescription: initialData?.roomDescription ?? "",
                buildingId: initialData?.buildingId ?? "",
                buildingDescription: initialData?.buildingDescription ?? "",
                campusId: initialData?.campusId ?? "",
                campusDescription: initialData?.campusDescription ?? "",
                capacity: initialData?.capacity ?? 0,
                floorId: initialData?.floorId ?? null,
                floorCode: initialData?.floorCode ?? null,
                floorDescription: initialData?.floorDescription ?? null,
                nm_high: initialData?.nm_high ?? 0,
                nm_long: initialData?.nm_long ?? 0,
                nm_width: initialData?.nm_width ?? 0,
                createdAt: initialData?.createdAt ?? null,
                updatedAt: initialData?.updatedAt ?? null,
                status: initialData?.status ?? 1, // Aquí se establece el valor inicial de "status"
              }}
              validationSchema={resourceSchema}
              onSubmit={handleSubmitResource}
            >
              {({ isSubmitting, errors, values, touched, setFieldValue }) => (
                <Form
                  id="resourceForm"
                  className={styleForm.flex__row}
                  style={{ width: "100%" }}
                >
                  <div className={styleForm.row__input}>
                    {editForm ? (
                      <InputText
                        type="text"
                        name="roomId"
                        placeholder="Código de integración"
                        TextLabel="Código de integración"
                        classNameInput={styleForm.input__input}
                        disabled={true}
                      />
                    ) : (
                      <div className={style.row__item}>
                        <label htmlFor="roomId">Código de integración</label>
                        <div>{values.roomId} </div>
                      </div>
                    )}
                  </div>
                  <div className={styleForm.row__input}>
                    {editForm ? (
                      <InputText
                        type="text"
                        name="roomCode"
                        placeholder="Ingresa código nuevo recurso"
                        TextLabel="Código recurso"
                        classNameInput={styleForm.input__input}
                        onValueChange={(newRoomCode) => {
                          // Actualizar roomCode
                          setFieldValue("roomCode", newRoomCode);
                          // Concatenar roomId basado en el nuevo valor de roomCode
                          const newRoomId = `${values.campusId}_${values.buildingDescription}_${newRoomCode}`;
                          setFieldValue("roomId", newRoomId);
                        }}
                      />
                    ) : (
                      <div className={style.row__item}>
                        <label htmlFor="roomCode">Código recurso</label>
                        <div>{values.roomCode} </div>
                      </div>
                    )}
                  </div>
                  <div className={styleForm.row__input}>
                    {editForm ? (
                      <InputText
                        type="text"
                        name="roomDescription"
                        placeholder="Ingresa descripción nuevo recurso"
                        TextLabel="Descripción recurso"
                        classNameInput={styleForm.input__input}
                      />
                    ) : (
                      <div className={style.row__item}>
                        <label htmlFor="rut">Descripción recurso</label>
                        <div>{values.roomDescription} </div>
                      </div>
                    )}
                  </div>
                  <div className={styleForm.row__input}>
                    {editForm ? (
                      <Field name="campusId">
                        {({
                          field,
                          form: { setFieldValue, validateForm, setFieldTouched, setFieldError, errors, touched, values },
                        }: FieldProps) => (
                          <>
                            <InputSearch
                              textLabel="Campus*"
                              classNameInput={styleForm.input__input}
                              serviceCall={serviceCallCampus}
                              onSelect={(selected) => {
                                // Actualiza el valor de campusId y campusDescription
                                setFieldValue("campusId", selected?.value ?? "");
                                setFieldValue("campusDescription", selected?.label ?? "");
                                // Actualiza roomId concatenando los valores relevantes
                                setFieldValue("roomId", `${selected?.value}_${values.buildingDescription}_${values.roomCode}`);
                                setCampusSelectCreate(selected);
                                // Marcar el campo como tocado
                                setFieldTouched("campusId", true, false);
                                // Forzar una validación completa del formulario
                                validateForm().then(() => {
                                  // Limpia manualmente el error si el campo es válido
                                  if (selected?.value) {
                                    setFieldError("campusId", ""); // Limpia el error manualmente
                                  }
                                });
                              }}
                              selected={{
                                label: values.campusDescription,
                                value: values.campusId,
                              }}
                              errorMessage={touched.campusId && errors.campusId ? errors.campusId : ""}
                            />
                          </>
                        )}
                      </Field>
                    ) : (
                      <div className={style.row__item}>
                        <label htmlFor="rut">Campus</label>
                        <div>{values.campusDescription} </div>
                      </div>
                    )}
                  </div>
                  <div className={styleForm.row__input}>
                    {editForm ? (
                      <Field name="buildingId">
                        {({ field, form: { setFieldValue, validateForm, setFieldTouched, setFieldError, errors, touched, values }, }: FieldProps) => (
                          <InputSearch
                            key={Math.random()}
                            textLabel="Edificio*"
                            classNameInput={styleForm.input__input}
                            serviceCall={serviceCallBuilding}
                            onSelect={(selected) => {
                              setFieldValue("buildingId", selected?.value ?? "");
                              setFieldValue("buildingDescription", selected?.label ?? "");
                              setFieldValue("roomId", `${values.campusId}_${selected?.label}_${values.roomCode}`);
                              // Marcar el campo como tocado
                              setFieldTouched("buildingId", true, false);
                              // Forzar una validación completa del formulario
                              validateForm().then(() => {
                                // Limpia manualmente el error si el campo es válido
                                if (selected?.value) {
                                  setFieldError("buildingId", ""); // Limpia el error manualmente
                                }
                              });
                            }}
                            selected={{ label: values.buildingDescription, value: values.buildingId }}
                            errorMessage={touched.buildingId && errors.buildingId ? errors.buildingId : ""}
                          />
                        )}
                      </Field>
                    ) : (
                      <div className={style.row__item}>
                        <label htmlFor="rut">Edificio</label>
                        <div>{values.buildingId} </div>
                      </div>
                    )}
                  </div>

                  <div className={styleForm.row__input}>
                    {editForm ? (
                      <InputText
                        type="text"
                        name="capacity"
                        placeholder="Ingresa la capacidad"
                        TextLabel="Capacidad*"
                        classNameInput={styleForm.input__input}
                      />
                    ) : (
                      <div className={style.row__item}>
                        <label htmlFor="capacity">Capacidad</label>
                        <div>{values.capacity} </div>
                      </div>
                    )}
                  </div>
                  <div className={styleForm.row__input}>
                    {editForm ? (
                      <InputText
                        type="text"
                        name="nm_high"
                        placeholder="Ingresa el alto"
                        TextLabel="Alto"
                        classNameInput={styleForm.input__input}
                      />
                    ) : (
                      <div className={style.row__item}>
                        <label htmlFor="nm_high">Alto</label>
                        <div>{values.nm_high} </div>
                      </div>
                    )}
                  </div>
                  <div className={styleForm.row__input}>
                    {editForm ? (
                      <InputText
                        type="text"
                        name="nm_width"
                        placeholder="Ingresa el alto"
                        TextLabel="Ancho"
                        classNameInput={styleForm.input__input}
                      />
                    ) : (
                      <div className={style.row__item}>
                        <label htmlFor="nm_width">Ancho</label>
                        <div>{values.nm_width} </div>
                      </div>
                    )}
                  </div>
                  <div className={styleForm.row__input}>
                    {values.createdAt ? (
                      <div className={style.row__item}>
                        <label htmlFor="nm_high">Fecha Creación</label>
                        <div>{values.createdAt} </div>
                      </div>
                    ) : ""}
                  </div>
                  <div className={styleForm.row__input}>
                    {values.updatedAt ? (
                      <div className={style.row__item}>
                        <label htmlFor="nm_high">Fecha Actualización</label>
                        <div>{values.updatedAt} </div>
                      </div>
                    ) : ""}
                  </div>


                  <div className={styleForm.row__input}>
                    {editForm ? (
                      <div className={styleForm.row__input}>
                        <label className={style.title}>Estado</label>
                        <Field name="status">
                          {({ field }: any) => (
                            <input
                              {...field}
                              id="status"
                              type="checkbox"
                              checked={field.value === 1}
                              onChange={(e) => {
                                const newValue = e.target.checked ? 1 : 0;
                                field.onChange({
                                  target: {
                                    name: field.name,
                                    value: newValue,
                                  },
                                });
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    ) : (
                      <div className={style.row__item}>
                        <label htmlFor="status">Estado</label>
                        <div
                          className={style.Card__resources_status}
                          style={{
                            background: getStatusInfo(values.status, typeResourceId.label).color,
                          }}
                        >
                          {getStatusInfo(values.status, typeResourceId.label).text}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styleForm.containerButtoms}>
                    {editForm && (
                      <>
                        <button
                          className={styleForm.buttomFooter + " " + styleForm.buttom_cancelar}
                          type="button"
                          onClick={() => {
                            setInitialData(null);
                            setEditForm(false);
                            setCreateForm(false);
                          }}
                        >
                          Cancelar
                        </button>
                        <button
                          type="submit"
                          form="resourceForm"
                          className={styleForm.buttomFooter}
                        >
                          Guardar cambios
                        </button>
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}
